import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

function CenterText() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#f9f9f9',
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 5, bgcolor: '#f9f9f9' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h5" align="center" sx={{ color: 'black', mb: 2, textDecoration: 'underline' }} >
          Want to Rent a Property?
          </Typography>
          <div align="center">
              <Button variant="contained"  href="/rental-application" target="_blank" color="info" size="large">
                Apply
              </Button>
            </div>
        </Grid>
        
      </Grid>
    </Container>
    </Box>
  );
}

export default CenterText;
