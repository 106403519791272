import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';

import Trash from '../images/aboutImages/trash.jpg';
import Rekey from '../images/aboutImages/rekey.jpg';
import Inspections from '../images/aboutImages/inspection.jpg';
import Price from '../images/aboutImages/price.jpg';
import Utilities from '../images/aboutImages/utilities.jpg';
import Winterize from '../images/aboutImages/winterize.jpg';
import Repair from '../images/aboutImages/repair.jpg';
import Lawn from '../images/aboutImages/lawncare.jpg';
import Clean from '../images/aboutImages/cleaning.jpg';
import Photo from '../images/aboutImages/photos.jpg';
import Tenant from '../images/aboutImages/tenant.jpg';
import Hazard from '../images/aboutImages/hazard.jpg';


const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 100,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
    border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    image: Rekey,
    title: 'Re-key and Secure Property',
    width: '40%',
  },
  {
    image: Inspections,
    title: 'Inspections',
    width: '20%',
  },
  {
    image: Price,
    title: 'Complete Broker Price Opinion',
    width: '40%',
  },
  {
    image: Trash,
    title: 'Trash Removal',
    width: '35%',
  },
  {
    image: Utilities,
    title: 'Initiate Utilities',
    width: '40%',
  },
  {
    image: Clean,
    title: 'Cleaning',
    width: '25%',
  },
  {
    image: Repair,
    title: 'Repairs & Renovation',
    width: '20%',
  },
  {
    image: Winterize,
    title: 'Winterize',
    width: '40%',
  },
  {
    image: Lawn,
    title: 'Lawn Care',
    width: '40%',
  },
  {
    image: Hazard,
    title: 'Analyze Marketing Hazards',
    width: '40%',
  },
  {
    image: Photo,
    title: 'House Photos',
    width: '20%',
  },
  {
    image: Tenant,
    title: 'Eviction & Tenant Placement',
    width: '40%',
  },
];

export default function ProductCategories() {
  return (
    <Container component="section" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h4" marked="center" align="center" >
        Services Offered
      </Typography>
      <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.image})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
