import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import alImage from '../images/al.jpg';
import background from '../images/background/bw_street.jpg';

function MeetAl() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    }}
  >
    <Container component="section" sx={{ mt: gridSizeXS ? 5 : 15, mb: gridSizeXS ? 5 : 15, bgcolor: 'white' }}>
      <Grid container>
        
        {/* Right-side Container */}
        <Grid item xs={6} md={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: gridSizeXS ? '.25rem' : '2rem',
              position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography variant="h1" gutter sx={{ color: 'black', mb: 1, fontSize: { xs:'1rem' , sm: '2rem', md: '4rem'}}} >
              Al Davis
              
            </Typography>
            <Typography variant="h6" sx={{ mb: gridSizeXS ? 1 : 2, position: 'relative', fontSize: { xs:'.45rem' , sm:'.75rem', md: '1.2rem'} }} >
            <span
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              Voted top 25 entrepreneurs in Baltimore City
              <span
                style={{
                  position: 'absolute',
                  content: '',
                  width: '100%',
                  height: gridSizeXS ? '1px' : '3px',
                  backgroundColor: 'black',
                  left: 0,
                  top: 0,
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  content: '',
                  width: '100%',
                  height: gridSizeXS ? '1px' : '3px',
                  backgroundColor: 'black',
                  left: 0,
                  bottom: 0,
                }}
              />
            </span>
            </Typography>
          
            <Typography variant="subtitle1" sx={{ my: 1, fontSize: {  xs:'.35rem' , sm:'.75rem', md: '1.15rem'} }} >
              My name is Al Davis, and I am a full-time Real Estate Investor, Consultant, and mentor assisting Buyers, Sellers, and Renters with their real estate needs. 
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1, fontSize: { xs:'.35rem' , sm: '.75rem', md: '1.15rem'} }} >
              I have worked in real estate acquisition, rehabilitation, marketing, sales, and development for the past twenty years. Over the years, I have acquired, renovated, leased, or sold over 160 units within the Baltimore area. I currently work with thirteen subsidized programs to shelter low income, disabled and disadvantaged individuals and families, and institutions such as Fannie Mae (Federal National Mortgage Association), HUD (Housing and Urban Development), and Freddie Mac (Federal Home Loan Mortgage Association). 
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1, fontSize: {  xs:'.35rem' , sm: '.75rem', md: '1.15rem'} }} >
              Intending to uplift and enhance Baltimore City, we have worked with Baltimore Officials and administrators to assist the development of units throughout Baltimore.
            </Typography>
            
          </Box>
        </Grid>
        {/* Larger Picture on the Left Side */}
        <Grid item xs={6} md={6}>
          <Box
            component="img"
            src={alImage}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              left: '10%', // Move the image to the right by 50%
            }}
          />
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetAl;
