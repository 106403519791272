import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

function PropertyPhotos({ property }) {
  const [photoUrls, setPhotoUrls] = useState([]);

  useEffect(() => {
    // Configure AWS SDK
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const s3 = new AWS.S3();

    // Fetch photo URLs from S3
    s3.listObjectsV2({ Bucket: 'skyymylesproperties' }, (err, data) => {
      if (err) {
        console.error(err);
        return;
      }
      //console.log("Data:", data.Contents);
      const urls = data.Contents.map((object) => {
        return s3.getSignedUrl('getObject', {
          Bucket: 'skyymylesproperties',
          Key: object.Key,
        });
      });

      setPhotoUrls(urls);
    });
  }, []);

  return (
    <div>
      {/*photoUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Property Photo ${index}`}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      ))*/}
    </div>
  );
}

export default PropertyPhotos;
