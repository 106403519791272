import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import City from '../images/background/city.jpg';

function More() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        backgroundImage: `url(${City})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top 60%',
        minHeight: '75vh', // Adjust the height to your preference
      }}
    >
      <Container sx={{ bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', padding: gridSizeXS ? '1rem' : '2rem', maxWidth: '75%', mt: gridSizeXS ? 4 : 0, mb: gridSizeXS ? 4 : 0 }}>
        <Typography variant="h2" sx={{ color: 'black', mb: 1, textAlign: 'center', fontSize: { xs:'3rem' , sm: '4rem', md: '5rem'}}}>
          More about us
        </Typography>
        <Typography variant="subtitle1" sx={{ my: gridSizeXS ? 2 : 1, textAlign: 'center', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1.5rem'} }}>
          As a leading provider of real estate services, we take pride in offering you the best options and are dedicated to serving the needs of our customers every day. We have acquired, renovated, rented, and sold over 160 properties over the past twenty years. Our company has valuable relationships with Banks, Subsidized programs, Contractors, Private Lenders, Title companies, Lawyers, Accountants, Appraisers, etc.. In which we allow our clients to have access to, through our business relationship.
        </Typography>
        <Typography variant="subtitle1" sx={{ my: gridSizeXS ? 2 : 1, textAlign: 'center', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1.5rem'} }}>
          We work with over 1500 investors who are looking to purchase or sell real estate with help from banks, investors, institutions, and homeowners who are looking to sell their real estate.
        </Typography>
        <Typography variant="subtitle1" sx={{ my: gridSizeXS ? 2 : 1, textAlign: 'center', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1.5rem'}}}>
          We pride ourselves in doing honest and ethical business which allows us to gain clients the best way, word of mouth.
        </Typography>
      </Container>
    </Box>
  );
}

export default More;
