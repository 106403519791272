import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// My Components
import AppBar from './components/AppBar';
import MobileAppBar from './MobileAppBar';

// MUI Components
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden'; 

// Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';

// Logo
import logo from './images/logos/logo-no-bottom.png';

//const pages = ['About Us', 'Properties', 'Seminars', 'Mentor Program', 'Loans'];
const resources = ['Trash Removal Service', 'Thomas Construction Group', 'Promotions', 'Rental Application'];
const propertiesDropDown = ['Current Listings', 'Developments', 'Sold Properties' ];
const learnDropDown = ['Podcast', 'Seminars', 'Mentor Program', ];
const loanDropDown = ['Hard Money Loans', 'Loan Application', ];

const styles = `
  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .app-bar-expanded {
    animation: slideUp 2s ease-in-out forwards;
  }

  .app-bar-collapsed {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 2s ease-in-out, opacity 2s ease-in-out;
  }
  
  .logo-container-expanded {
    animation: slideUp 2s ease-in-out forwards;
  }

  .logo-container-collapsed {
    transform: translateY(50%);
    opacity: 0;
  }
  
  .nav-links-expanded {
    animation: slideUp 2.5s ease-in-out forwards;
  }

  .nav-links-collapsed {
    transform: translateY(50%);
    opacity: 0;
  }
`;

function isTabletOriPad() {
  // Check if the User-Agent string contains keywords commonly found in tablet or iPad devices
  const userAgent = navigator.userAgent.toLowerCase();
  return /ipad|tablet|android/.test(userAgent);
}

function AppAppBar() {
  const [scrolling, setScrolling] = useState(false);
  const [openResources, setOpenResources] = useState(null);
  const [openProperties, setOpenProperties] = useState(null);
  const [openLearn, setOpenLearn] = useState(null);
  const [openLoan, setOpenLoan] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedLinks, setExpandedLinks] = useState(false);
  const anchorRefProp = useRef(null);
  const anchorRefRes = useRef(null);
  const anchorRefLearn = useRef(null);
  const anchorRefLoan = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 821 }); 
  const isMobile2 = isTabletOriPad();
  const location = useLocation();
  const isHomePage = location.pathname === '/'; // Check if the current pathname is the homepage ("/")
  const isOnAdminPage = location.pathname.includes('/admin') || location.pathname.includes('/loan-application');; 
  
  
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    //console.log("Home Page?", isHomePage);
    //console.log("Mobile?", isMobile);
    // Delay setting 'expanded' to true
    let timeoutId;

    if(isHomePage){
      timeoutId = setTimeout(() => {
          setExpanded(true);
      }, 3000); 
    

      timeoutId = setTimeout(() => {
          setExpandedLinks(true);
      }, 4000); 
    } else {
      setExpanded(true);
      setExpandedLinks(true);
    }

    // Clean up event listeners and the timeout
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // Handle Properties Dropdown
  const handleMouseLeaveProp = () => {
    setOpenProperties(false);
  };

  const handlePropToggle = () => {
    setOpenProperties((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenResources(false);
    setOpenLoan(false);
  }

  const handleCloseProp = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenProperties(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProperties(false);
    } else if (event.key === 'Escape') {
      setOpenProperties(false);
    }
  }

  //Handle Resources Dropdown
  const handleMouseLeave = () => {
    setOpenResources(false);
  };

  function handleListKeyDownRes(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenResources(false);
    } else if (event.key === 'Escape') {
      setOpenResources(false);
    }
  }

  const handleResToggle = () => {
    setOpenResources((prevOpen) => !prevOpen);
    setOpenLearn(false);
    setOpenProperties(false);
    setOpenLoan(false);
  }

  const handleCloseRes = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenResources(false);
  };

  // Handle Learn Dropdown
  const handleMouseLeaveLearn = () => {
    setOpenLearn(false);
  };

  const handleLearnToggle = () => {
    setOpenLearn((prevOpen) => !prevOpen);
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLoan(false);    
  }

  const handleCloseLearn = (event) => {
    if (anchorRefProp.current && anchorRefProp.current.contains(event.target)) {
      return;
    }
    setOpenLearn(false);
  };

  function handleListKeyDownLearn(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenLearn(false);
    } else if (event.key === 'Escape') {
      setOpenLearn(false);
    }
  }

  // Handle Loan Dropdown
  const handleMouseLeaveLoan = () => {
    setOpenLoan(false);
  };

  const handleLoanToggle = () => {
    setOpenLoan((prevOpen) => !prevOpen);
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLearn(false);
  }

  const handleCloseLoan = (event) => {
    if (anchorRefLoan.current && anchorRefLoan.current.contains(event.target)) {
      return;
    }
    setOpenLoan(false);
  };

  function handleListKeyDownLoan(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenLoan(false);
    } else if (event.key === 'Escape') {
      setOpenLoan(false);
    }
  }

  //Close All Dropdowns
  const closeToggels = () => {
    setOpenProperties(false);
    setOpenResources(false);
    setOpenLearn(false);
    setOpenLoan(false);
  }

  return (

    <>
      {/* Inline CSS */}
      <style>{styles}</style>

    
    <Grid container>
    <Grid item xs={12}>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        position="fixed"
        style={{ 
          backgroundColor: isOnAdminPage ? 'rgba(15, 15, 15, 1)' : scrolling ? 'rgba(15, 15, 15, 1)' : 'transparent', 
          transition: 'background-color 0.5s', 
          //height: '100px',
          zIndex: '2',
        }}
        className={isHomePage ? (expanded ? 'app-bar-expanded' : 'app-bar-collapsed') : ''}
      >
        <Container maxWidth="xl" >
        
          <Toolbar
            disableGutters
            style={{
              //display: 'flex',
              //justifyContent: 'space-between',
              //alignItems: 'center',
              height: '100%', // Added height to center vertically
              width: '100%',
            }}
            //className="app-bar-element"
          >
           
            <Box 
              sx={{ display: 'flex', alignItems: 'center' }}
              className={
                isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''
              }
              width="25%"
              justifyContent="center"
            >             
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center', // Center vertically with the Avatar
                  fontFamily: 'Merriweather',
                  fontWeight: 300,
                  letterSpacing: '.2rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  fontSize: { md: '1rem', lg: '1.5rem'} //resize based on screensize
                }}
              >
               
                  <Avatar src={logo}
                    alt="Logo"
                    sx={{
                      width: '50px',
                      height: '90px',
                      marginRight: '10px',
                      //position: 'absolute',
                      top: '7px',
                      //transform: 'translateY(-50%)',
                    }} />
              
                SKYY MYLES LLC
              </Typography>
            </Box>
           
            <Box 
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{ flexGrow: 1, zIndex: 10, /*marginLeft: 'auto', marginRight: 'auto'*/  }}
              className={
                isHomePage ? (expandedLinks ? 'nav-links-expanded' : 'nav-links-collapsed') : ""
              } 
            >
              
                <Button
                  key={'aboutus'}
                  component={Link} 
                  to={'/aboutus'}
                  color="inherit" 
                  sx={{ 
                    //my: 2, 
                    //mr: 1, 
                    //ml: 1,
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    //fontSize: '17px',
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    fontSize: { md: '1rem', lg: '1.3rem'}
                  }}
                  onMouseEnter={closeToggels}
                >
                  About
                </Button>
                
        
                {/* Properties Dropdown */}
                <Button
                  key={'properties'}
                  component={Link} 
                  to={'/current-listings'}
                  color="inherit"
                  variant="inherit"
                  sx={{ 
                    //my: 2, 
                    //mr: 1, 
                    //ml: 1,
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    //display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    fontSize: { md: '1rem', lg: '1.3rem'}       
                  }}
                  onMouseEnter={handlePropToggle}
                  aria-controls={openProperties ? 'composition-menu' : undefined}
                  aria-expanded={openProperties ? 'true' : undefined}
                  aria-haspopup="true"
                  ref={anchorRefProp}
                >
                  PROPERTIES
                </Button>

                <Popper
                  open={openProperties}
                  anchorEl={anchorRefProp.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                  onMouseLeave={handlePropToggle}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseProp}>
                          <MenuList
                            autoFocusItem={openProperties}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            sx={{flexDirection: 'column'}}
                          >
                            {propertiesDropDown.map((prop) => (
                              <MenuItem
                                key={prop}
                                component={Link}
                                to={`/${prop.toLowerCase().replace(/\s/g, '-')}`}
                                onClick={handleMouseLeaveProp} // Close the menu when a resource is clicked
                                
                              >
                                {prop}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                {/* Learn Dropdown */}
                <Button
                  key={'learn'}
                  component={Link} 
                  to={'/seminars'}
                  color="inherit"
                  variant="inherit"
                  sx={{ 
                    //mt: 0, 
                    //mr: 2, 
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    //display: 'block', 
                    fontFamily: 'Merriweather',
                    letterSpacing: '.1rem',
                    '&:hover': { textDecoration: 'underline' },
                    fontSize: { md: '1rem', lg: '1.3rem'}       
                  }}
                  onMouseEnter={handleLearnToggle}
                  aria-controls={openLearn ? 'composition-menu' : undefined}
                  aria-expanded={openLearn ? 'true' : undefined}
                  aria-haspopup="true"
                  ref={anchorRefLearn}
                >
                  Learn
                </Button>

                <Popper
                  open={openLearn}
                  anchorEl={anchorRefLearn.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                  onMouseLeave={handleLearnToggle}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseLearn}>
                          <MenuList
                            autoFocusItem={openLearn}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDownLearn}
                            sx={{flexDirection: 'column'}}
                          >
                            {learnDropDown.map((prop) => (
                              <MenuItem
                                key={prop}
                                component={Link}
                                to={`/${prop.toLowerCase().replace(/\s/g, '-')}`}
                                onClick={handleMouseLeaveLearn} // Close the menu when a resource is clicked
                                
                              >
                                {prop}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                    
                {/* Loans Dropdown */}
                <Button
                  key={'loans'}
                  component={Link} 
                  to={'/hard-money-loans'}
                  color="inherit"
                  variant="inherit"
                  sx={{ 
                    //my: 2, 
                    //mr: 2, 
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    fontSize: { md: '1rem', lg: '1.3rem'},
                    letterSpacing: '.1rem',
                    fontFamily: 'Merriweather',
                  }}
                  onMouseEnter={handleLoanToggle}
                  aria-controls={openLoan ? 'composition-menu' : undefined}
                  aria-expanded={openLoan ? 'true' : undefined}
                  aria-haspopup="true"
                  ref={anchorRefLoan}
                >
                  Loans
                </Button>

                <Popper
                  open={openLoan}
                  anchorEl={anchorRefLoan.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                  onMouseLeave={handleLoanToggle}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseLoan}>
                          <MenuList
                            autoFocusItem={openLoan}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDownLoan}
                            sx={{flexDirection: 'column'}}
                          >
                            {loanDropDown.map((loan) => (
                              <MenuItem
                                key={loan}
                                component={Link}
                                to={`/${loan.toLowerCase().replace(/\s/g, '-')}`}
                                onClick={handleMouseLeaveLoan} // Close the menu when a resource is clicked
                              >
                                {loan}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                
                {/* Resources Dropdown */}
                <Button
                  key={'resources'}
                  component={Link} 
                  to={'/rental-application'}
                  color="inherit"
                  variant="inherit"
                  sx={{ 
                    //my: 2, 
                    //mr: 2, 
                    m: {md: 0, lg: 1},
                    color: 'white', 
                    display: 'block', 
                    '&:hover': { textDecoration: 'underline' },
                    fontSize: { md: '1rem', lg: '1.3rem'},
                    letterSpacing: '.1rem',
                    fontFamily: 'Merriweather',
                  }}
                  onMouseEnter={handleResToggle}
                  aria-controls={openResources ? 'composition-menu' : undefined}
                  aria-expanded={openResources ? 'true' : undefined}
                  aria-haspopup="true"
                  ref={anchorRefRes}
                >
                  RESOURCES
                </Button>

                <Popper
                  open={openResources}
                  anchorEl={anchorRefRes.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                  onMouseLeave={handleResToggle}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseRes}>
                          <MenuList
                            autoFocusItem={openResources}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDownRes}
                            sx={{flexDirection: 'column'}}
                          >
                            {resources.map((resource) => (
                              <MenuItem
                                key={resource}
                                component={Link}
                                to={`/${resource.toLowerCase().replace(/\s/g, '-')}`}
                                onClick={handleMouseLeave} // Close the menu when a resource is clicked
                              >
                                {resource}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

                
            </Box>
            

            <Box 
              display="flex"
              flexDirection="row"
              justifyContent="right"
              alignItems="center" // Center vertically
              width="25%"
              sx={{ flexGrow: 1 }}
              className={ isHomePage ? (expanded ? 'logo-container-expanded' : 'logo-container-collapsed') : ''}
            >
              <IconButton color="inherit" href="https://www.instagram.com/aldz3/" target="_blank" >
                <InstagramIcon sx={{ fontSize: '30px'}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.facebook.com/turnkeyrental/" target="_blank">
                <FacebookIcon sx={{ fontSize: '30px'}}/>
              </IconButton>

              <IconButton color="inherit" href="https://www.youtube.com/@therealestatepodcast6111" target="_blank">
                <YouTubeIcon sx={{ fontSize: '35px'}} />
              </IconButton>

              <IconButton color="inherit" href="mailto:contact@skyymylesllc.com" target="_blank">
                <EmailIcon sx={{ fontSize: '30px'}}/>
              </IconButton>
            </Box>
 
          </Toolbar>
        
        </Container>
      </AppBar>
    </Box>
    </Grid>
    </Grid>

    </>
  );
}

export default AppAppBar;
