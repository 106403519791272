import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import conservatory from '../images/aboutImages/conservatory.jpg';

function MeetSkyyMyles() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#f9f9f9', mb: 0 }}
    >
    <Container component="section" sx={{ mt: gridSizeXS ? 0 : 10, mb: gridSizeXS ? 0 : 10 }}>
      <Grid container>
        {/* Larger Picture on the Left Side */}
        {gridSizeXS ? null : (
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={conservatory}
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                position: 'relative', // Set position to relative
                right: '10%', // Move the image to the right by 50%

              }}
            />
          </Grid>
        )}

        {/* Right-side Container */}
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              position: gridSizeXS ? '' : 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: gridSizeXS ? '' : 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography variant={gridSizeXS ? "h3" : "h1"} component="h2"  gutterBottom sx={{ color: 'black'}} >
              WHO ARE WE?
              {gridSizeXS ? null : (
                <span
                  style={{
                    position: 'absolute',
                    top: '15%', // Position the underline directly under the typography
                    left: 0,
                    right: 0,
                    height: '3px',
                    backgroundColor: 'black',
                    transform: 'translateY(-15px)', // Move the underline up by its height
      
                  }}
                />
              )}
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1 }} >
            <span style={{ eight: 'bold' }}>Skyy Myles LLC</span> is one of the most trusted real estate companies in the <span style={{ fontWeight: 'bold' }}>Baltimore, Maryland area</span>. With over twenty years of experience in the real estate industry, we work hard to meet all of your needs. At Skyy Myles, our goal is to show investors how to become profitable, by investing in real estate. Whether acquiring wholesale properties for income streams. We help with every facet of the process, from finding the right property and determining the right price to pay to negotiating prices and connecting with the best companies for title work. We will also assist investors in finding qualified renters for their properties.
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1 }} >
            <span style={{ fontWeight: 'bold' }}>Skyy Myles LLC</span> practices honesty, fairness, and hard work in dealing with people who strive to become profitable in real estate. We are staffed by a highly skilled team that understands the dynamics of our local real estate market. Our agent’s knowledge and relationships in the industry will help you find the best solutions for your real estate needs. Our staff will communicate through the process and respect your time by working efficiently. Our agents work hard to build lasting relationships with every client.
            </Typography>
            
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
}

export default MeetSkyyMyles;
