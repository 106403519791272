import React, { useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './ApplicationHero';
import FormPage from './Form';
import { Form } from 'react-router-dom';

function Index() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <FormPage/>
    </React.Fragment>
  );
}

export default withRoot(Index);
