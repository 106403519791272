import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Import your images
import image1 from '../images/developmentImages/frontDevelopment.png';
import image2 from '../images/developmentImages/develop2.png';
import image3 from '../images/developmentImages/develop3.png';
import image4 from '../images/developmentImages/develop4.png';
import image5 from '../images/developmentImages/develop5.png';
import image6 from '../images/developmentImages/develop6.png';
import image7 from '../images/developmentImages/develop7.png';
import image8 from '../images/developmentImages/develop8.png';
import floorplan from '../images/developmentImages/floorplan.png';
import floorplan2 from '../images/developmentImages/floorplan2.png';
import map from '../images/developmentImages/map.png';

const imagePaths = [image1, image2, image3, image4, image5, image6, image7, image8, floorplan, floorplan2, map]; // Add all your image paths here

function ImageCarousel() {
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 426) {
      setGridSizeXS(true)
    } 

  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const imageStyle = {
    maxWidth: '100%', // Ensure the image width fits the container
    height: gridSizeXS ? '250px' : '500px',   // Allow the height to adjust proportionally
    maxHeight: '500px', // Set a maximum height to limit the image size
    align: 'center',
    margin: '0 auto',
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: '#f9f9f9', py: 5, px: 5 }}>
      <Divider
        role="presentation"
        sx={{ width: '100%', fontSize: '24px', textAlign: 'center', fontWeight: 'bold' }}
      >
        Photos
      </Divider>
      <div style={{ margin: '1rem 0' }}>
        <Slider {...settings} ref={sliderRef}>
          {imagePaths.map((imagePath, index) => (
            <div key={index}>
              <Paper elevation={3} style={{ padding: '1rem', textAlign: 'center' }}>
                <img
                  src={imagePath}
                  alt={`Image ${index}`}
                  style={imageStyle}
                />
              </Paper>
            </div>
          ))}
        </Slider>
        <Box sx={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              sliderRef.current.slickNext();
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </div>
    </Box>
  );
}

export default ImageCarousel;
