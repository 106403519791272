//import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { LoginView } from '../sections/login';

// ----------------------------------------------------------------------

export default function LoginPage() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      

      <LoginView />
    </>
  );
}
