import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import Carousel from 'react-material-ui-carousel';
import { useParams, useLocation } from 'react-router-dom';

import ProductHero from './PropertyHero';
import MapTextLeft from './MapTextLeft';
import ImageCarousel from './Carousel';
import CenterText from './CenterText';

function PropertyDetail() {
    const location = useLocation();
    const { property } = location.state || {};

    // State to store property details and photos
    const [photoUrls, setPhotoUrls] = useState([]);
    const [frontUrl, setFrontUrl] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (property) {

            // Simulate fetching photo URLs
            AWS.config.update({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                region: process.env.REACT_APP_AWS_REGION,
            });
            const s3 = new AWS.S3();

            const params = {
                Bucket: 'skyymylesproperties',
                Prefix: `${property.address}`, // Specify the folder name here
              };

            s3.listObjectsV2(params, (err, data) => {
                if (err) {
                    console.error(err);
                    return;
                }

                const folder = data.Contents.filter((obj) => obj.Key.includes(`${property.address}`));
                const photoUrls = folder.map((obj) => {
                    const url = s3.getSignedUrl('getObject', { Bucket: 'skyymylesproperties', Key: obj.Key });
                    //console.log("Img Url:", url)
                    return url;
                });

                //Shift the first image to last 
                const firstObj = photoUrls.shift();
                photoUrls.push(firstObj);
                setPhotoUrls(photoUrls); //Set Image Urls for Carousel  

                const frontImg = folder.find((obj) => obj.Key.includes("front.")); // Use find to get a single object
                if (frontImg) {
                    //console.log("Front:", frontImg.Key);
                    const url = s3.getSignedUrl('getObject', { Bucket: 'skyymylesproperties', Key: frontImg.Key });
                    setFrontUrl(url);
                } else {
                    //Get Front Image not found, use first image as defaulted image 
                    if(folder.length > 0){
                        const defaultImageUrl = folder[1]
                        const defaulturl = s3.getSignedUrl('getObject', { Bucket: 'skyymylesproperties', Key: defaultImageUrl.Key });
                        setFrontUrl(defaulturl);
                        //console.log("PhotoURL:", defaulturl);
                    }
                    
                }
                            
            });
        }
    }, [property]);

    return (
        <div>
            {photoUrls ? (
                <div>
                    <ProductHero imgUrl={frontUrl} property={property}/>
                    <MapTextLeft property={property}/>
                    {property.salemethod.toLowerCase() === 'rent' && <CenterText />}
                    <ImageCarousel imageUrls={photoUrls}/>
                </div>
            ) : (
                <p>Loading property details...</p>
            )}
        </div>
    );
}

export default PropertyDetail;
