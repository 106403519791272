import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import {MarkerF} from '@react-google-maps/api'


function PropertyMap({ property }) {
  
  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  // Render the map or an error message
  return (
    <div className="App">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={{lat: 39.341783622076086, lng: -76.66618875765418}} zoom={17}>
                <Marker position={{lat: 39.341783622076086, lng: -76.66618875765418}} />
            </GoogleMap>
        </LoadScript>
    </div>
  );
}

export default PropertyMap;
