import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

function BottomVideo() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: 0, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h2" gutter align="center" sx={{ color: 'white', mb: 2 }} >
          Check Us Out!
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 4}} >
          View us on Youtube, @therealestatepodcast6111, and other major podcast streaming services.
          </Typography>
          <iframe 
            width="100%" 
            height="700" 
            src="https://www.youtube.com/embed/dTJAIZcuaCY"
            title="“The Real” Estate Podcast  Ep.1 Getting into Real Estate with No Money"
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
          
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default BottomVideo;
