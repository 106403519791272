import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  //position: 'relative',
  //display: 'flex',
  //alignItems: 'center',
  height: '100vh',
  [theme.breakpoints.up('sm')]: {
    height: '100vh',
    minHeight: 500,
    maxHeight: 1300, 
  },
}));

const Background = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  
  zIndex: -2,
});

const TextContainer = styled(Container)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%', // Set the width to 100%
  backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
  padding: '16px', // Adjust padding as needed
  //display: 'flex',
  flexDirection: 'column',
  //alignItems: 'flex-start', // Align text to the left
  //justifyContent: 'flex-end', // Push text to the bottom
});

function ProductHeroLayout(props) {
  const { sxBackground, property } = props;

  return (
    <ProductHeroLayoutRoot>
      <Background sx={sxBackground} />
     
      <TextContainer>
        {/* Property address */}
        <Typography variant="h2" color="white" sx={{mb: 1, fontSize: { xs:'3rem' , sm: '2rem', md: '3rem', lg: '3.75rem', xl: '4rem'}}}>
        Development - Pimlico Road & Wylie Avenue 
        </Typography>
        
        {/* Property price */}
        {/*<Typography variant="h4" color="white">
          ${property.price.toLocaleString()}.00
          </Typography>*/}
      </TextContainer>
    </ProductHeroLayoutRoot>
  );
}

ProductHeroLayout.propTypes = {
  children: PropTypes.node,
  sxBackground: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ProductHeroLayout;
