import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Add Property',
    path: '/admin/add-property',
    //icon: icon('ic_user'),
  },
  {
    title: 'Update Property',
    path: '/admin/update-property',
    //icon: icon('ic_user'),
  },
];

export default navConfig;
