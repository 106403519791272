// config.js
const config = {
    development: {
      apiUrl: 'http://localhost:3001/api', // Update with your development backend URL
    },
    production: {
      //apiUrl: 'https://mammothwebsite.com/api', // Update with your production backend URL
      //apiUrl: 'https://skyymylesllc.com/api',
      apiUrl: 'https://skyymyles.com/api',
    },
  };
  
  export default config;
  