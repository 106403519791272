
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
//import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from './components/Typography.js';
import TextField from './components/TextField.js';
import IconButton from '@mui/material/IconButton';

//Icons
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Copyright() {
  return (
    <React.Fragment>
      {'© '} SKYY MYLES LLC {' '} {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const CenteredContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

export default function AppFooter() {
  const [gridSizeSX, setGridSizeSX] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;    
    // Define your custom breakpoints here
    if (width < 600) {
      setGridSizeSX(true);
    } 
  }, []); 

  return (
    <Box
      component="footer"
      sx={{ display: 'flex', bgcolor: '#0f0f0f', color: 'white',  }}
    >
      <CenteredContainer sx={{ my: 8 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item sx={12} md={4}>
            <Grid container direction="column" spacing={2} alignItems={gridSizeSX ? "center" : "flex-start"} sx={{ height: 150, }}>
                <Grid item>
                  <Typography variant="h4" sx={{ color: 'white',  borderBottom: '2px solid white', }}>
                    SKYY MYLES LLC
                  </Typography>
                </Grid>
              
              <Grid item>
                <Copyright />
              </Grid>
              <Grid item>
                <Typography variant="caption" sx={{ color: 'white' }}>
                  {'Website made by '}
                  {/*<Link href="https://mammothwebsites.com" title="mammothwebsites.com" sx={{ color: 'white' }}>
                    Mammoth Websites
                  </Link>*/}    
                  <a href="https://mammothwebsites.com" target="_blank" rel="noopener noreferrer">
                    Mammoth Websites
                  </a>              
                </Typography>
              </Grid>
              
            </Grid>
          </Grid>

          <Grid item sx={12} md={4} >
          <Grid container direction="column" spacing={2} alignItems="center" sx={{ height: 175 }}>
          <Grid item>
                <Typography variant="h6" sx={{ color: 'white' ,  borderBottom: '2px solid white' }}>
                  Contact Us
                </Typography>
              </Grid>
          <Grid item>
                <Typography variant="h6" sx={{ color: 'white' }}>
                  Office Cell/Text: (443) 871 - 8980
                </Typography>
              </Grid>
          <Grid item>
                <IconButton color="inherit" href="https://www.instagram.com/aldz3/" target="_blank">
                  <InstagramIcon />
                </IconButton>

                <IconButton color="inherit" href="https://www.facebook.com/turnkeyrental/" target="_blank">
                  <FacebookIcon />
                </IconButton>

                <IconButton color="inherit" href="https://www.youtube.com/@therealestatepodcast6111" target="_blank">
                <YouTubeIcon />
              </IconButton>

              <IconButton color="inherit" href="mailto:contact@skyymylesllc.com" target="_blank">
                <EmailIcon />
              </IconButton>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item sx={12} md={4}  >
            <Grid container direction="column" spacing={1} justifyContent={gridSizeSX ? "center" : "flex-start"} alignItems="center" sx={{ height: 150 }}>
            <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
              <Typography variant="h6" gutterBottom sx={{ color: 'white', borderBottom: '2px solid white'}}>
                Pages
              </Typography>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={Link} to={"/"} sx={{ color: 'white' }}>Home</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={Link} to={"/current-listings"} sx={{ color: 'white' }}>Properties</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={Link} to={"/privacy-policy"} sx={{ color: 'white' }}>Privacy Policy</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={Link} to={"/terms-and-conditions"} sx={{ color: 'white' }}> Terms and Conditions </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={Link} to={"/admin/login"} sx={{ color: 'white' }}>Admin</Link>
              </Box>
            </Box>
          </Grid>
          </Grid>
          
          
        </Grid>
      </CenteredContainer>
    </Box>
  );
}
