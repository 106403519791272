import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

function PhotoTextLeft() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: 'rgba(23, 23, 23, 1)', py: gridSizeXS ? 0 : 5, px: 5 }}
    >
      <Grid container spacing={4} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //padding: '2rem',
              //position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h2" gutterBottom sx={{ mt: gridSizeXS ? 0 : 5, color: 'white' }} >
            Take Action!
            </Typography>
            <Typography variant="h5" align="center" sx={{ my: 1, color: 'white', lineHeight: '1.7'  }} >
            With over a thousand clients, this is your chance of being apart of a life-changing experience. We want to teach and train you on methods to build a successful real estate business. With our program, you will know what to do, how to do it, and when to do it. Please help us help you achieve your goal by joining our mentor program. 
            </Typography>

          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8} >
         
          <iframe 
            width="100%" 
            height="500" 
            src="https://www.youtube.com/embed/C_laU8va6I8" 
            title="MentorProgramInfo" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoTextLeft;
