import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

function CenterText() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 0, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h1" align="center" sx={{ color: 'white', mb: 2, textDecoration: 'underline', fontSize: { xs:'1.5rem' , sm: '2rem', md: '4rem'} }} >
          Our Development Mission
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 2, lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1.5rem'}}} >
          We aim to deliver suitable housing solutions for low-income, disadvantaged, disabled, and employed individuals and families. 
          Our mission is centered on creating comprehensive, community-focused plans that garner broad support and can be embraced by specific localities. 
          These plans embody each community's unique vision and lay the groundwork for future development through strategic partnerships with nonprofit organizations.
          </Typography>
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default CenterText;
