import React, { useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './DevelopmentHero';
import CenterText from './CenterText';
import PhotoTextLeft from './PhotoTextLeft';

function Index() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <CenterText/>
      <PhotoTextLeft/>

    </React.Fragment>
  );
}

export default withRoot(Index);
