import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import alImage from '../images/al.jpg';
import background from '../images/background/bw_street.jpg';

function BottomVideo() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h2" gutter align="center" sx={{ color: 'white', mb: 2,  borderBottom: '2px solid white', fontSize: { xs:'1.75rem' , sm: '3.25rem', md: '4.5rem', lg: '5rem'} }} >
          Jump Start Your Career
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 4, fontSize: { xs:'1.25rem' , sm: '2rem', md: '2.25rem', lg: '3rem'}}} >
          Real Estate is a challenging journey, but with our help it doesn’t have to be. Do you want guidance on your journey?
          </Typography>
          <iframe 
            width="100%" 
            height="600" 
            src="https://www.youtube.com/embed/elnyG4OeOXc" 
            title="MentorProgramIntro"
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default BottomVideo;
