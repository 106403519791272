import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import alImage from '../images/al.jpg';
import background from '../images/background/bw_street.jpg';

function BottomVideo() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h2" gutter align="center" sx={{ color: 'white', mb: 2, fontSize: { xs:'1.5rem' , sm: '2.5rem', md: '4rem'} }} >
            It’s time to make a change.
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 3, fontSize: { xs:'1rem' , sm: '1.5rem', md: '2rem'}}} >
          Do you want to learn the real ways to make real money in real estate? 
          In our seminar programs, we will teach you the ins and outs of real estate investing from start to finish. 
          </Typography>
          <iframe 
            width="100%" 
            height="600"
            src="https://www.youtube.com/embed/x4oEoblQ2Mw" 
            title="RealEstateSeminar" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen
          >
          </iframe>
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default BottomVideo;
