import React, { useState, useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './PodcastHero';
import PhotoTextRight from './PhotoTextRight';
import BottomVideo from './BottomVideo';

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <PhotoTextRight/>
      <BottomVideo/>
    </React.Fragment>
  );
}

export default withRoot(Index);
