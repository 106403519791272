import * as React from 'react';
import withRoot from './withRoot.js';

import AboutHero from './PPHero.js';
import PrivacyPolicyDocument from './PrivPolicyDocument.js';

function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <PrivacyPolicyDocument/>
    </React.Fragment>
  );
}

export default withRoot(Index);
