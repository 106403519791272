import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import ListItemButton from '@mui/material/ListItemButton';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

import { usePathname } from '../../routes/hooks';
import { RouterLink } from '../../routes/components';

import { useResponsive } from '../../hooks/use-responsive';

import { account } from '../../_mock/account';

import Logo from '../../components/logo';
import Scrollbar from '../../components/scrollbar';

import { NAV } from './config-layout';
import navConfig from './config-navigation';

import { getAuth, signOut } from 'firebase/auth';
import { useRouter } from '../../routes/hooks';

// ----------------------------------------------------------------------

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const router = useRouter();

  const upLg = useResponsive('up', 'lg');
  const upMd = useResponsive('up', 'md');
  //console.log("UPLG?", upLg);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderAccount = (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar src={account.photoURL} alt="photoURL" />

      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{account.displayName}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {account.role}
        </Typography>
      </Box>
    </Box>
  );

  const handleLogout = async () => {
    const auth = getAuth(); // Get the Firebase Authentication instance

    try {
      await signOut(auth); // Sign out the user
      // You can also redirect the user to a different page or perform other actions after logout.
      router.push('/');
      console.log('User has been logged out');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderMenu = (
    
    <Stack component="nav" spacing={0.5} sx={{ px: 2 , mt: 5}}>
      {navConfig.map((item) => (
        <NavItem key={item.title} item={item} />
      ))}

    <ListItemButton
      onClick={handleLogout}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'h5',
        //color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
      }}
    >
      <Box component="span"> Logout </Box>
    </ListItemButton>
      
    </Stack>
  );

  
  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        mt: upLg ? 12 : (upMd ? 11 : 8)
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderMenu}
        </Box>
      ) : (
        <AppBar position="static" justifyContent="center">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            
            <Box
              sx={{
                //flexGrow: 1, // This will make the navConfig items take up available space
                display: 'flex', // Use flexbox to center items horizontally
                justifyContent: 'center', // Center items horizontally
              }}
            >
              {navConfig.map((item) => (
                <NavItem key={item.title} item={item} />
              ))}
            </Box>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                typography: 'h5',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
              }}
            >
              <Box component="span"> Logout </Box>
            </ListItemButton>
          </Toolbar>
        </Container>
      </AppBar>

      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        //minHeight: 44,
        //borderRadius: 0.75,
        typography: 'h5',
        //color: 'text.secondary',
        textTransform: 'capitalize',
        //fontWeight: 'fontWeightMedium',
        /*...(active && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),*/
      }}
    >
      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
