import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function ImageCarousel({ imageUrls }) {
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [gridSizeXS, setGridSizeXS] = useState(false);
  
  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 426) {
      setGridSizeXS(true)
    } 

  }, []);

  const settings = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: '#f9f9f9', py: 5, px: 5 }}
    >
      <Divider
        role="presentation"
        sx={{ width: '100%', fontSize: '24px', textAlign: 'center', fontWeight: 'bold', }}
      >
        Photos
      </Divider>
      <div style={{ margin: '1rem 0' }}>
        <Slider {...settings} ref={sliderRef}> {/* Attach the ref to the Slider component */}
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <Paper elevation={3} style={{ padding: '1rem', textAlign: 'center' }}>
                <img
                  src={imageUrl}
                  alt={`Image ${index}`}
                  style={{ maxWidth: '100%', maxHeight: '500px', margin: '0 auto', height: gridSizeXS ? '250px' : '500px', }}
                />
              </Paper>
            </div>
          ))}
        </Slider>
        <Box sx={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => {
              sliderRef.current.slickPrev(); // Go to the previous slide using the ref
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              sliderRef.current.slickNext(); // Go to the next slide using the ref
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </div>
    </Box>
  );
}

export default ImageCarousel;
