// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//require('dotenv').config();


// Your web app's Firebase configuration/
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBwSR58rLcZX8EwkDvdBtbUMoVdDagU-iM",
    authDomain: "skyymyles-a9942.firebaseapp.com",
    projectId: "skyymyles-a9942",
    storageBucket: "skyymyles-a9942.appspot.com",
    messagingSenderId: "995872168384",
    appId: "1:995872168384:web:50849cfc48844305121025",
    measurementId: "G-H7F0NL5C3Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

//export default app;
//export { auth };