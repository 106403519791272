import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropertyCard from './PropertyCard';
import { 
  Grid, 
  Container, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
  FormControlLabel,
  ListItemText,
  Checkbox,
} from '@mui/material';
import config from '../config.js';

//Icons
import SearchIcon from '@mui/icons-material/Search'; 
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '../components/Typography';

function Properties() {
  const [properties, setProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filterBedrooms, setFilterBedrooms] = useState("0+ Beds");
  const [filterBathrooms, setFilterBathrooms] = useState("0+ Baths");
  const [filterMinPrice, setFilterMinPrice] = useState('');
  const [filterMaxPrice, setFilterMaxPrice] = useState('');
  const [selectedTypes, setSelectedTypes] = useState(['wholesale', 'turnkey', 'rent']);
  const [sortOrder, setSortOrder] = useState('highest');
  const [noProperties, setNoProperties] = useState(false);
 
 const environment = process.env.NODE_ENV || 'development'; // Determine the environment (e.g., development or production)

  // Set the base URL for Axios requests
  const api = axios.create({
    baseURL:  config[environment].apiUrl.replace('/api', ''),
  });
 
   useEffect(() => {
     //console.log("API:", config[environment].apiUrl.replace('/api', '') + "/api/properties");
     api.get('/api/properties')
       .then((response) => {
         setProperties(response.data);
         const soldItems = response.data.filter((obj) => obj.status === "sold");
         setFilteredProperties(soldItems);
         if(soldItems.length === 0){
            setNoProperties(true);
         }
         //console.log("Retrieved Properties Data:", response.data);
       })
       .catch((error) => {
         console.error("Axios Get Properties Error:", error);
       });
  }, []);

  const filterProperties = (value, type) => {
    let filtered = properties;

    // Apply filters
    if (searchQuery !== '' && type === 'search' && value !== "") {
      filtered = filtered.filter((property) => {
        const propertyData = `${property.address} ${property.city} ${property.state} ${property.zip}`.toLowerCase();
        return propertyData.includes(searchQuery.toLowerCase());
      });
    }

    if (filterBedrooms !== '' && type === 'bedrooms' && value !== "") {
      filtered = filtered.filter((property) => property.bedrooms >= parseInt(value));
    }

    if (filterBathrooms !== '' && type === 'bathrooms' && value !== "") {
      filtered = filtered.filter((property) => property.bathrooms >= parseInt(value));
    }

    if (filterMinPrice !== '' && type === 'minprice' && value !== "") {
      filtered = filtered.filter((property) => property.price >= parseInt(value));
    }
    
    if (filterMaxPrice !== '' && type === 'maxprice' && value !== "") {
      filtered = filtered.filter((property) => property.price <= parseInt(value));
    }

    if (selectedTypes !== '' && type === 'type' && value.length !== 0) {
      filtered = filtered.filter((property) => value.includes(property.salemethod));
    }

    if (value === 'lowest' && type === 'sort') {
      filtered = filtered.sort((a, b) => a.price - b.price);
    } else if (value === 'highest' && type === 'sort') {
      filtered = filtered.sort((a, b) => b.price - a.price);
    }

    //console.log("Filtered:", filtered);
    setFilteredProperties(filtered);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterProperties(event.target.value, "search");
  };

  const handleFilterChange = (event, filterType) => {
    const value = event.target.value;
    switch (filterType) {
      case 'bedrooms':
        setFilterBedrooms(value);
        break;
      case 'bathrooms':
        setFilterBathrooms(value);
        break;
      case 'minprice':
        setFilterMinPrice(value);
        break;
      case 'maxprice':
        setFilterMaxPrice(value);
        break;
      default:
        break;
    }
    filterProperties(value, filterType);
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;

    let temp = []
    // Toggle the selected type
    if (selectedTypes.includes(selectedType)) {
      temp = selectedTypes.filter((type) => type !== selectedType)
      setSelectedTypes(temp);
    } else {
      temp = selectedType;
      setSelectedTypes(temp);
    }
    //console.log("Types:", temp);
    // Filter properties based on selected types
    filterProperties(temp, 'type');
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    filterProperties(event.target.value, 'sort');
  };

  const clearSearch = () => {
    setSearchQuery(''); // Clear the search query
    filterProperties(); // Trigger filtering with an empty search query
  };

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }}>
      <Grid container  sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={12} sm={7} md={7} sx={{mr: 2}}>
          <TextField
            label={
              <div style={{ marginLeft: '35px' }}>
                Search by address, city, state, zip ...
              </div>
            }
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ mb: 2, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery && ( // Render the clear icon only when there's content in the search bar
                    <IconButton onClick={clearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: searchQuery !== '', }}
          />
        </Grid>

        <Grid item xs={5.5} sm={6} md={2} sx={{mr:1}}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Sort By Price</InputLabel>
            <Select
              label="Sort By Price"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <MenuItem value="highest">Highest Price</MenuItem>
              <MenuItem value="lowest">Lowest Price</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5.5} sm={6} md={2} sx={{ml:1}}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel> Sale Type</InputLabel>
            <Select
              label="Sale Type"
              multiple
              value={selectedTypes}
              onChange={handleTypeChange}
              renderValue={(selected) => `${selected.length} Types` }
              sx={{ minWidth: '100px' }} // Set the width as desired
            >
              <MenuItem value="wholesale">
                <Checkbox checked={selectedTypes.includes('wholesale')} />
                <ListItemText primary="Wholesale" />
              </MenuItem>
              <MenuItem value="rent">
                <Checkbox checked={selectedTypes.includes('rent')} />
                <ListItemText primary="Rent" />
              </MenuItem>
              <MenuItem value="turnkey">
                <Checkbox checked={selectedTypes.includes('turnkey')} />
                <ListItemText primary="Turnkey" />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        
      </Grid>
     
      

      <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Grid item xs={5.5} sm={6} md={2}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 0 }}>
            <InputLabel>Bedrooms</InputLabel>
            <Select
              label="Bedrooms"
              value={filterBedrooms}
              onChange={(e) => handleFilterChange(e, 'bedrooms')}
            >
              <MenuItem value="0+ Beds">0+ Beds</MenuItem>
              <MenuItem value="1+ Beds">1+ Beds</MenuItem>
              <MenuItem value="2+ Beds">2+ Beds</MenuItem>
              <MenuItem value="3+ Beds">3+ Beds</MenuItem>
              <MenuItem value="4+ Beds">4+ Beds</MenuItem>
              <MenuItem value="5+ Beds">5+ Beds</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={5.5} sm={6} md={2}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 0 }}>
            <InputLabel>Bathrooms</InputLabel>
            <Select
              label="Bathrooms"
              value={filterBathrooms}
              onChange={(e) => handleFilterChange(e, 'bathrooms')}
            >
              <MenuItem value="0+ Baths">0+ Baths</MenuItem>
              <MenuItem value="1+ Baths">1+ Baths</MenuItem>
              <MenuItem value="2+ Baths">2+ Baths</MenuItem>
              <MenuItem value="3+ Baths">3+ Baths</MenuItem>
              <MenuItem value="4+ Baths">4+ Baths</MenuItem>
              <MenuItem value="5+ Baths">5+ Baths</MenuItem>
            </Select>
          </FormControl>
        </Grid>

               
        <Grid item xs={5} sm={5} md={2}>
          <TextField
             label={
              <div style={{ marginLeft: '15px' }}>
                Min Price
              </div>
            }
            variant="outlined"
            fullWidth
            value={filterMinPrice}
            onChange={(e) => handleFilterChange(e, 'minprice')}
            sx={{ mb: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              ),
            }}          
            InputLabelProps={{ shrink: filterMinPrice !== '', }} 
          />
        </Grid>

        <Grid item xs={1} sm={1} md={.25} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="body1" style={{marginTop: '0px'}}>to</Typography>
        </Grid>
        
        <Grid item xs={5} sm={5} md={2} sx={{mb: 0}}>
          <TextField
            label={
              <div style={{ marginLeft: '15px' }}>
                Max Price
              </div>
            }
            variant="outlined"
            fullWidth
            value={filterMaxPrice}
            onChange={(e) => handleFilterChange(e, 'maxprice')}
            sx={{ mb: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              ),
            }}          
            InputLabelProps={{ shrink: filterMaxPrice !== '', }} 
          />
        </Grid>
      </Grid>

      <Grid xs={12} item sx={{ mb: 2 }}/>

      <Divider role="presentation" style={{width:'100%'}}>
        <Typography variant="h4">Current Sold Listings</Typography>
      </Divider>

      {/* Property Cards */}
      {noProperties === false && 
      <Grid container spacing={4} sx={{mt: .25}}>
        {filteredProperties.map((property) => (
          <Grid item key={property._id} xs={12} sm={6} md={4}>
            <PropertyCard property={property} />
          </Grid>
        ))}
      </Grid>
      }
      {noProperties === true && 

        <Typography align="center" variant="h5" sx={{mt: 4, mb: 4}}>There are no sold properties at this time</Typography>
     
      }
    </Container>
  );
}

export default Properties;
