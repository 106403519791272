import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button.js';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';

import image from '../images/developmentImages/frontDevelopment.png';

function PhotoTextLeft() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: 'rgba(23, 23, 23, 1)', py: 5, px: gridSizeXS ? 2 : 10 }}
    >

      <Grid container spacing={4} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={6} md={8} sx={{ position: 'relative', mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              position: 'absolute',
              //alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            
            <Typography variant="h3" gutterBottom align="left" sx={{ color: 'white', borderBottom: '2px solid white', fontSize: { xs:'1rem' , sm: '2rem', md: '3rem'} }} >
            Townhouse Developments
            </Typography>
            <Typography variant="h4" gutterBottom align="left" sx={{ color: 'white', fontSize: { xs:'1rem' , sm: '2rem', md: '2rem'} }} >
            Pimlico Rd & Wylie Ave
            </Typography>
            <div align="left">
              <Button variant="contained" align="left" component={Link} to={"/developments/pimlico-and-wylie"} color="info" size={gridSizeXS ? "small" : "large"}>
                Read More
              </Button>
            </div>

          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={10} md={8}>
        <Box
            component="img"
            src={image}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              //position: 'relative', // Set position to relative
              //right: '10%', // Move the image to the right by 50%
             
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoTextLeft;
