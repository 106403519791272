import React from 'react';
import { Container, Grid, Typography, TextField, Button } from '@mui/material';
import harbor from '../images/background/inner_harbor.jpg';
import NewsletterLayout from './NewsletterLayout';

export default function Newsletter() {
  return (
        <NewsletterLayout
          sxBackground={{
            backgroundImage: `url(${harbor})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
    );
}
