import * as React from 'react';
import withRoot from './withRoot';

import ProductHero from './DumpHero';
import BottomImage from './BottomImage';

function Index() {
  return (
    <React.Fragment>
      <ProductHero/>
      <BottomImage/>
    </React.Fragment>
  );
}

export default withRoot(Index);
