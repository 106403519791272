//import { Helmet } from 'react-helmet-async';

import { AddPropertyView } from '../sections/addproperty/view';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';

import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Suspense, useEffect } from 'react';
import { auth } from '../../firebase';

export default function AddPropertyPage() {
  const navigate = useNavigate(); // Use useNavigate for redirection

  useEffect(() => {
    //window.scrollTo(0, 0);
    // Check if auth.currentUser is null
    if (auth.currentUser === null) {
      // Redirect the user to the login page
      navigate('/admin');
    }
  }, [navigate]);

  return (
    <>
      <DashboardLayout>
        <Suspense>
          <AddPropertyView />
        </Suspense>
      </DashboardLayout>
    </>
  );
}


