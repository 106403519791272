import React from 'react';

const PrivacyPolicyDocument = () => {
  const pdfUrl = '/PrivacyPolicy.pdf'; // Provide the path to your PDF document here

  return (
    <div>
        <iframe src={pdfUrl} width='100%' height='1000px' />
    </div>
  );
};

export default PrivacyPolicyDocument;
