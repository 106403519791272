import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, Marker, LoadScript, useLoadScript } from "@react-google-maps/api";
import {MarkerF} from '@react-google-maps/api'
import Skeleton from '@mui/material/Skeleton';

function PropertyMap({ property }) {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLocation(); // Call the fetchLocation function when the component mounts

  }, [property.address]);

  const fetchLocation = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const fullAddr = `${property.address}, ${property.city}, ${property.state} ${property.zip}`
      // Construct the Geocoding API URL
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        fullAddr
      )}&key=${apiKey}`;

      //console.log("Geocode URL:", apiUrl);
      // Fetch data from the API
      const response = await fetch(apiUrl);
      const data = await response.json();
      //console.log("Data:", data);

      if (data.results && data.results.length > 0) {
        const result = data.results[0].geometry.location;
        //console.log("Location:", result);
        setLocation(result);
      } else {
        setError('No location data found');
      }
    } catch (err) {
      setError('Error fetching location data');
    }
  };

  const mapContainerStyle = {
    width: '100%',
    height: '75vh',
  };

  // Render the map or an error message
  return (
    <div className="App">
      {!location ? (
        <Skeleton variant="rounded" width="100%" height='50vh' />
      ) : (
        <LoadScript async googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={{lat: location.lat, lng: location.lng}} zoom={17}>
                <Marker position={{lat: location.lat, lng: location.lng}} />
            </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
}

export default PropertyMap;
