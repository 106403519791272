import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import Tower from '../images/constructImages/towerConstruct.jpg'

function PhotoTextLeft() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: 'rgba(23, 23, 23, 1)', py: 5, px: 5 }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              //position: 'absolute',
              //alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h4" align={gridSizeXS ? "center" : "left"} gutterBottom sx={{ borderBottom: '2px solid white', color: 'white', fontSize: { xs:'2.5rem' , sm: '3rem', md: '2rem', lg: '3rem'} }} >
            <span style={{ fontWeight: 'bold' }}>THOMAS CONSTRUCTION GROUP, LLC</span>
            </Typography>
            <Typography variant="h5" align={gridSizeXS ? "center" : "left"} sx={{ mb: 4, color: 'white', lineHeight: '1.7'  }} >
            Thomas Construction Group, LLC has performed work on commercial and residential projects as general an subcontractor, in the Washington-metropolitan Baltimore, Maryland area.
            </Typography>

            <Typography variant="h4" align={gridSizeXS ? "center" : "left"} gutterBottom sx={{ color: 'white' }} >
            <span style={{ fontWeight: 'bold' }}>Credentials:</span>
            </Typography>
            <Typography variant="h5" align="left" sx={{ my: 1, color: 'white', lineHeight: '1.7'  }} >
            <ul>
                <li>Maryland MDOT CERTIFIED (17-302) MBE/DBE/SDB</li>
                <li>Prince George's County, Maryland MBE (18-13686) & County-based MBE (cbsb-19-1134)</li>
                <li>Baltimore City MBE</li>
                <li>Maryland Home Improvement Commission licensed (MHIC# 94793)</li>
                <li>Fully insured for general liability and workers compensation</li>
                <li>Bonded up to $5M</li>
                <li>Baltimore city pre-qualified for projects up to $1.5M</li>
                <li>Hire & mentor locally to stimulate the economy</li>
              </ul>
            </Typography>

            <Typography variant="h4" align={gridSizeXS ? "center" : "left"} gutterBottom sx={{ color: 'white' }} >
            <span style={{ fontWeight: 'bold' }}>Experienced in:</span>
            </Typography>
            <Typography variant="h5" align="left" sx={{ my: 1, color: 'white', lineHeight: '1.7'  }} >
            <ul>
                <li>Underground sewer line replacements</li>
                <li>Commercial reconstruction of foot-ways</li>
                <li>Commercial and residential historical restorations</li>
                <li>Commercial and residential renovations</li>
                <li>Build long-term business alliances</li>
                <li>Educating our clients about our “means and methods” of our superior work ethic</li>
              </ul>
            </Typography>

          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8}>
            <Box
                component="img"
                src={Tower}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
          </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoTextLeft;
