import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';
import { Divider } from '@mui/material';

import DumpTruck from '../images/dumpImages/dumpTruck.jpg';

function BottomImage() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 10, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h4" gutter align="center" sx={{ color: 'white', mb: 3 }} >
           Demolition Hauling and Trash Removal Service
          </Typography>

          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 2}} >
          Need help removing trash or hauling equipment?
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 4}} >
          Text 443-871-8980 for a quote
          </Typography>
          <Grid item xs={12}>
            <Box
                component="img"
                src={DumpTruck}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
          </Grid>
          
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default BottomImage;
