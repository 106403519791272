import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import conservatory from '../images/seminarImages/seminar1.jpg';

function PhotoTextRight() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      bgcolor: 'rgba(23, 23, 23, 1)',
      py: 0,
      px: 5,
      display: 'flex',
      alignItems: 'center', // Vertically center content
    }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} sm={8} md={8}>
        <Box
            component="img"
            src={conservatory}
            sx={{
              width: '100%',
              height: '75vh',
              objectFit: 'cover',
              //position: 'relative', // Set position to relative
              //right: '10%', // Move the image to the right by 50%
             
            }}
          />
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} sm={8} md={8} sx={{ position: 'relative' }}>
          <Box
              sx={{
                //display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '1rem',
                //position: 'absolute',
                alignItems: 'center', // Center text horizontally
                top: '50%', // Adjust vertical alignment
                //transform: gridSizeXS ? "" : 'translateY(-50%)', // Adjust vertical alignment
                //zIndex: 1,
                
              }}
            >
              <Typography variant="h2" align="center" gutterBottom 
                sx={{ color: 'white',  borderBottom: '2px solid white', fontSize: { xs:'2rem' , sm: '2rem', md: '2.5rem', lg: '4rem'}}} 
              >
                Let Us Show You
              </Typography>
              <Typography variant="h5" align="center" sx={{ my: 2, color: 'white', lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1rem', md: '1.25rem', lg: '1.75rem'}  }} >
              It all begins with an idea. At our seminars, we will teach you the real truth about real estate investing in the modern-day. We demonstrate multiple financial investment opportunities to start your career as a real estate investor and build your portfolio. We strive to direct everyone on a path to success. We will coach you on cash needs, lending options, analyzing multiple deals, minimizing risk, maximizing profits, and many more fundamental topics. Our goal is to prepare you for the road ahead. 
              </Typography>
              
              
            </Box>
        </Grid>
      </Grid>

      </Box>
  );
}

export default PhotoTextRight;
