import React, { useState, useEffect } from 'react';
import withRoot from './withRoot';

import SeminarHero from './SeminarHero.js';
import BottomVideo from './BottomVideo.js';
import PhotoTextRight from './PhotoTextRight';
import PhotoTextLeft from './PhotoTextLeft';
import BottomVideo2 from './BottomVideo2';

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <React.Fragment>
      <SeminarHero/> 
      <BottomVideo/>
      <PhotoTextRight/>
      <PhotoTextLeft/>
      <BottomVideo2/>
    </React.Fragment>
  );
}

export default withRoot(Index);
