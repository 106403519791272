/* eslint-disable import/first */
import React, { lazy, Suspense } from 'react';

//My Components
const ProductCategories = lazy(() => import('../aboutPageElements/ProductCategories'));
const ProductSmokingHero = lazy(() => import('./ProductSmokingHero'));
const AppFooter = lazy(() => import('../AppFooter'));
const ProductHero = lazy(() => import('./ProductHero'));
const ProductValues = lazy(() => import('./featured'));
const ProductHowItWorks = lazy(() => import('./ProductHowItWorks'));
const Meet = lazy(() => import('./Meet'));
const AppAppBar = lazy(() => import('../AppAppBar'));
import withRoot from './withRoot';
const Knowledge = lazy(() => import('./knowledge'));
const InstagramGrid = lazy(() => import('./Instagram'));
const Newsletter = lazy(() => import('./Newsletter'));
const Carousel = lazy(() => import('./Carousel'));

// Import your company logos or images here
import Logo1 from '../images/logos/the-afro-logo.jpg';
import Logo2 from '../images/logos/balt-sun.png';
import Logo3 from '../images/logos/forbes.png';
const images = [Logo1, Logo2, Logo3];

function Index() {
  return (
    <React.Fragment>
      <ProductHero />
      {/*<Carousel images={images} />*/}
      <Meet />
      <ProductValues />
      <Knowledge />
      <InstagramGrid/>
      <Newsletter/>
    </React.Fragment>
  );
}

export default withRoot(Index);
