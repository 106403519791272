import React, { useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './PropertiesHero';
import Properties from './Properties';
import PropertyPhotos from './PropertyPhotos';

function Index() {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <Properties/>
      <PropertyPhotos/>

    </React.Fragment>
  );
}

export default withRoot(Index);
