import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography.js';
import AboutHeroLayout from './LoanHeroLayout';
import backgroundImage from '../images/background/loanbackground.jpg';

export default function ProductHero() {
  return (
    <AboutHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    />

  );
}
