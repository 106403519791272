import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

function CenterText() {

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    <Container component="section" sx={{ mt: 5, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h2" gutter align="center" sx={{ color: 'white', mb: 2 }} >
          We Are Here To Help
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'white', mb: 4, lineHeight: '1.7'}} >
          With over twenty years of experience, we are heavily relied on by hundreds of investors as the real estate forerunners. We are sought out by many for real estate questions, investments, and training in Baltimore. We teach hundreds of people yearly on how to build wealth quickly and show them how to achieve their goals within five years.
          </Typography>
        </Grid>

       
      </Grid>
    </Container>
    </Box>
  );
}

export default CenterText;
