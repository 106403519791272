import React, { useState, useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './MentorHero';
import BottomVideo from './BottomVideo';
import CenterText from './CenterText';
import PhotoTextRight from './PhotoTextRight';
import PhotoTextLeft from './PhotoTextLeft';

function Index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <BottomVideo/>
      <PhotoTextRight/>
      <PhotoTextLeft/>
      {/* Mentor Form */}
    </React.Fragment>
  );
}

export default withRoot(Index);
