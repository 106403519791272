import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { 
  Grid, 
  Container, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
  FormControlLabel,
  ListItemText,
  Checkbox,
  Box,
  Typography,
  Button,
} from '@mui/material';

import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';

import PropertyMap from './Map';

function MapTextLeft(imports) {
  const {property} = imports;
  //console.log("Property:", property);
  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: '#f9f9f9', px: 5, pt: 2 }}
    >
      <Grid container spacing={4} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              position: 'relative',
              //alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h2" align='center' sx={{ color: 'black', mb: 1, fontSize: { xs:'3rem' , sm: '4rem', md: '3.5rem', lg:"4rem"} }} >
            Property Details
            </Typography>
            <Divider role="presentation" style={{width:'100%', border: '2px solid black',}}/>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1, mb: 1 }}>
              <BedIcon sx={{ fontSize: 45, mr: 1 }} /> {/* You need to replace BedIcon with your actual bed icon */}
              <Typography variant="h6" align="center" sx={{ mr: 5 }}>
                {property.bedrooms} Beds
              </Typography>
              <BathtubIcon sx={{ fontSize: 40, mr: 1 }} /> {/* You need to replace BathIcon with your actual bath icon */}
              <Typography variant="h6">
                {property.bathrooms} Baths
              </Typography>
            </Box>


            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Type:</span> {property.type} 
            </Typography>

            {property.salemethod.toLowerCase() === "rental" &&
              <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
                <span style={{ fontWeight: 'bold' }}>Rent:</span> ${property.rentprice.toLocaleString()}.00/month
              </Typography>
            }

            {property.salemethod.toLowerCase() === "wholesale" &&
              <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
                <span style={{ fontWeight: 'bold' }}>Price:</span> ${property.price.toLocaleString()}.00
              </Typography>
            }

            {property.salemethod.toLowerCase() === "turnkey" && 
              <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
                <span style={{ fontWeight: 'bold' }}>Price:</span> ${property.price.toLocaleString()}.00
              </Typography>
            }

            {property.salemethod.toLowerCase() === "turnkey" && 
              <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
                <span style={{ fontWeight: 'bold' }}>Rent:</span> ${property.rentprice.toLocaleString()}.00/month
              </Typography>
            }

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Sale Type:</span> {property.salemethod.charAt(0).toUpperCase() + property.salemethod.slice(1)} 
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Negotiable?:</span> {property.negotiable} 
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Status:</span> {property.status} 
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Date Listed:</span> {property.date} 
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Details:</span> {property.description}
            </Typography>

            {property.salemethod.toLowerCase() === 'rental' &&
              <Button 
                variant="contained"
                key={'rental-application'}
                component={Link} 
                to={'/rental-application'}
                size="large"
                sx={{mt: 2}}
              >
                Apply to Rent 
              </Button> 
            }

          </Box>
        </Grid>

        {/* Right Side Map*/}
        <Grid item xs={16} md={8}>
          <PropertyMap property={property} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MapTextLeft;
