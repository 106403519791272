import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

function PhotoTextLeft() {

  const [gridSizeXS, setGridSizeXS] = useState(false);
  const [gridSizeSM, setGridSizeSM] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 426) {
      setGridSizeXS(true)
      setGridSizeSM(false)
    } 
    else if (window.innerWidth < 769 && window.innerWidth > 425) {
      setGridSizeXS(false)
      setGridSizeSM(true)
    } else {
      setGridSizeXS(false)
      setGridSizeSM(false)
    }

  }, []);

  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: 'rgba(23, 23, 23, 1)', py: gridSizeXS ? 0 : 5, px: 5 }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} sm={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //padding: '2rem',
              //position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: gridSizeXS ? "" : (gridSizeSM ? '' : ''), // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h2" gutterBottom sx={{ color: 'white', borderBottom: '2px solid white', fontSize: { xs:'2rem' , sm: '2rem', md: '2.5rem', lg: '4rem'}}} >
              Join Us
            </Typography>
            <Typography variant="h5" align="center" sx={{ my: 1, color: 'white', lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1rem', md: '1.25rem', lg: '1.75rem'} }} >
            We host monthly meetings to prepare you to become a real estate investor. Our seminars change every session to ensure you get the best education and are well rounded in the topic of real estate. If you need any assistance ranging from finding properties and tenants to construction, we are here to help. Connect and network with the top forerunners in Baltimore's real estate. Hope to see you there! 
            </Typography>

          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} sm={8} >
         
          <iframe 
            width="100%" 
            height="400" 
            src="https://www.youtube.com/embed/PE4fesTO6UA" 
            title="RealEstateSeminarRecap" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoTextLeft;
