import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

const indented = {
    marginLeft: '40px',
  };

const indented2 = {
    marginLeft: '80px',
};

function LoanFormat({ formData }) {
  return (
    <Container>
      <Typography variant="h1">Loan Application</Typography>
      
      <Grid container>
        <Grid item xs={12} md={6}>
          <div>
            <strong>Applicant Name:</strong> {formData.main_firstname} {formData.main_lastname}
          </div>
          <div>
            <strong>Applicant Phone:</strong> {formData.main_phone}
          </div>
          <div>
            <strong>Applicant Email:</strong> {formData.main_email}
          </div>
          <div>
            <strong>Applicant Title/Role:</strong> {formData.main_title}
          </div>
          <div>
            <strong>Applicant Type:</strong> {formData.main_borrower}
          </div>
          <h2>Individual Borrower(s)</h2>

        <h3 style={indented}>Individual Borrower #1</h3>
            <div style={indented2}>
                <strong>Borrower #1 Name:</strong> {formData.b1_ind_name} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Address:</strong> {formData.b1_ind_address} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Phone:</strong> {formData.b1_ind_phone} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Email:</strong> {formData.b1_ind_email} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 DOB:</strong> {formData.b1_ind_dob} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 SSN/TIN:</strong> {formData.b1_ind_ssn} 
            </div>

        <h3 style={indented}>Individual Borrower #1 Financial Declaration:</h3>
            <div style={indented2}>
                <strong>Borrower #1 Credit:</strong> {formData.b1_ind_credit} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Prior Tax Income:</strong> {formData.b1_ind_income} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Total Cash Reserves:</strong> {formData.b1_ind_reserves} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Employer Name:</strong> {formData.b1_ind_employer_name} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Employer Phone:</strong> {formData.b1_ind_employer_address} 
            </div>
            <div style={indented2}>
                <strong>Borrower #1 Employer Address:</strong> {formData.b1_ind_employer_phone} 
            </div>
    
        <h3 style={indented}>Individual Borrower #1 Financial Declaration</h3>
            <div style={indented2}>
                <strong>Are there any outstanding judgments against you?</strong> {formData.b1_ind_dec_1} 
            </div>
            <div style={indented2}>
                <strong>Are you in or have you been declared bankrupt in the past 7 years?</strong> {formData.b1_ind_dec_2} 
            </div>
            <div style={indented2}>
                <strong>Are you a party to any current lawsuits?</strong> {formData.b1_ind_dec_3} 
            </div>
            <div style={indented2}>
                <strong>Do you have any unpaid federal or state taxes?</strong> {formData.b1_ind_dec_4} 
            </div>
            <div style={indented2}>
                <strong>Have you ever had any property you own foreclosed against?</strong> {formData.b1_ind_dec_5} 
            </div>
            <div style={indented2}>
                <strong>Have you ever defaulted on a loan?</strong> {formData.b1_ind_dec_6} 
            </div>
            <div style={indented2}>
                <strong>Please Explain Any "Yes" Answers Here</strong> {formData.b1_ind_dec_desc} 
            </div>

    
        <h3 style={indented}>Individual Borrower #2</h3>
            <div style={indented2}>
                <strong>Borrower #2 Name:</strong> {formData.b2_ind_name} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Address:</strong> {formData.b2_ind_address} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Phone:</strong> {formData.b2_ind_phone} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Email:</strong> {formData.b2_ind_email} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 DOB:</strong> {formData.b2_ind_dob} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 SSN/TIN:</strong> {formData.b2_ind_ssn} 
            </div>

        <h3 style={indented}>Individual Borrower #2 Financial Declaration:</h3>
            <div style={indented2}>
                <strong>Borrower #2 Credit:</strong> {formData.b2_ind_credit} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Prior Tax Income:</strong> {formData.b2_ind_income} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Total Cash Reserves:</strong> {formData.b2_ind_reserves} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Employer Name:</strong> {formData.b2_ind_employer_name} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Employer Phone:</strong> {formData.b2_ind_employer_address} 
            </div>
            <div style={indented2}>
                <strong>Borrower #2 Employer Address:</strong> {formData.b2_ind_employer_phone} 
            </div>
    
        <h3 style={indented}>Individual Borrower #2 Financial Declaration</h3>
            <div style={indented2}>
                <strong>Are there any outstanding judgments against you?</strong> {formData.b2_ind_dec_1} 
            </div>
            <div style={indented2}>
                <strong>Are you in or have you been declared bankrupt in the past 7 years?</strong> {formData.b2_ind_dec_2} 
            </div>
            <div style={indented2}>
                <strong>Are you a party to any current lawsuits?</strong> {formData.b2_ind_dec_3} 
            </div>
            <div style={indented2}>
                <strong>Do you have any unpaid federal or state taxes?</strong> {formData.b2_ind_dec_4} 
            </div>
            <div style={indented2}>
                <strong>Have you ever had any property you own foreclosed against?</strong> {formData.b2_ind_dec_5} 
            </div>
            <div style={indented2}>
                <strong>Have you ever defaulted on a loan?</strong> {formData.b2_ind_dec_6} 
            </div>
            <div style={indented2}>
                <strong>Please Explain Any "Yes" Answers Here</strong> {formData.b2_ind_dec_desc} 
            </div>





    <h2>Entity Borrower(s)</h2>
        <h3 style={indented}>Entity Info</h3>
            <div style={indented2}>
                <strong>Entity Name:</strong> {formData.ent_name}
            </div>
            <div style={indented2}>
                <strong>Entity Address:</strong> {formData.ent_address} 
            </div>
            <div style={indented2}>
                <strong>Entity Tax ID Number:</strong> {formData.ent_tax_id} 
            </div>
            <div style={indented2}>
                <strong>Entity State of Formation:</strong> {formData.ent_state_form} 
            </div>
        <h3 style={indented}>Entity Owner #1 Info</h3>
            <div style={indented2}>
                <strong>Entity Owner #1 Name:</strong> {formData.b1_ent_name} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #1 Phone:</strong> {formData.b1_ent_phone} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #1 Email:</strong> {formData.b1_ent_email} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #1 DOB:</strong> {formData.b1_ent_dob} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #1 SSN/TIN:</strong> {formData.b1_ent_ssn} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #1 Address:</strong> {formData.b1_ent_address} 
            </div>
        <h3 style={indented}>Entity Owner #2 Info</h3>
            <div style={indented2}>
                <strong>Entity Owner #2 Name:</strong> {formData.b2_ent_name} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 Phone:</strong> {formData.b2_ent_phone} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 Email:</strong> {formData.b2_ent_email} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 DOB:</strong> {formData.b2_ent_dob} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 SSN/TIN:</strong> {formData.b2_ent_ssn} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 Address:</strong> {formData.b2_ent_address} 
            </div>
            <div style={indented2}>
                <strong>Entity Owner #2 Percentage Ownership:</strong> {formData.b2_ent_percent} 
            </div>
        <h3 style={indented}>Other Entity Owners Info</h3>
            <div style={indented2}>
                <strong>Other Entity Owners Infomation:</strong> {formData.other_ent_desc} 
            </div>
        <h3 style={indented}>Entity Financial Info</h3>
            <div style={indented2}>
                <strong>Entity Owner's Credit :</strong> {formData.ent_owner_credit}
            </div>
            <div style={indented2}>
                <strong>Entity/Owner Total Cash Reserves:</strong> {formData.ent_owner_reserves} 
            </div>
            <div style={indented2}>
                <strong>Entity Gross Income:</strong> {formData.ent_owner_gross} 
            </div>
            <div style={indented2}>
                <strong>Entity Net Income:</strong> {formData.ent_owner_net} 
            </div>
     
        <h3 style={indented}>Entity Financial Declaration</h3>
            <div style={indented2}>
                <strong>Are there any outstanding judgments against entity or any owners of entity?</strong> {formData.ent_dec_1} 
            </div>
            <div style={indented2}>
                <strong>Has entity or any owner declared bankruptcy the past 7 years?</strong> {formData.ent_dec_2} 
            </div>
            <div style={indented2}>
                <strong>Is entity or any owner a party to any current lawsuits?</strong> {formData.ent_dec_3} 
            </div>
            <div style={indented2}>
                <strong>Does entity or any owner have any unpaid federal or state taxes?</strong> {formData.ent_dec_4} 
            </div>
            <div style={indented2}>
                <strong>Has entity or any owner ever had any property you own foreclosed against?</strong> {formData.ent_dec_5} 
            </div>
            <div style={indented2}>
                <strong>Has entity or any owner ever defaulted on a loan?</strong> {formData.ent_dec_6} 
            </div>
            <div style={indented2}>
                <strong>Please Explain Any "Yes" Answers: </strong> {formData.ent_dec_desc} 
            </div>



    <h2>Loan Request Details</h2>
        <div style={indented}>
            <strong>What Is The Total Requested Loan Amount?</strong> {formData.loan_req_amount} 
        </div>
        <div style={indented}>
            <strong>How Long Will You Need The Loan?</strong> {formData.loan_req_length} 
        </div>
        <div style={indented}>
            <strong>When Do You Need To Close By?</strong> {formData.loan_req_close} 
        </div>
        <div style={indented}>
            <strong>Is This a Refinance or New Financing?</strong> {formData.loan_req_finance} 
        </div>

    <h2>Existing Loan Details</h2>
        <div style={indented}>
            <strong>What Is The Estimated Current Loan Balance Being Paid Off?</strong> {formData.loan_exist_balance} 
        </div>
        <div style={indented}>
            <strong>Loan Default/Foreclosure Status: </strong> {formData.loan_exist_status} 
        </div>
        <div style={indented}>
            <strong>Add Any Additional Info You Think Necessary About Your Existing Loan:</strong> {formData.loan_exist_desc} 
        </div>
        <div style={indented}>
            <strong>What Are You Using the Loan Proceeds For?</strong> {formData.use_of_funds} 
        </div>
        <div style={indented}>
            <strong>What is the Purchase Price of the Property?</strong> {formData.purchase_price} 
        </div>

    <h2>Collateral Details</h2>
        <div style={indented}>
            <strong>List Address(es) of ALL Real Estate Being Offered as Collateral:</strong> {formData.collat_addr} 
        </div>
        <div style={indented}>
            <strong>Estimated Total Value of All Real Estate Assets Being Offered as Collateral:</strong> {formData.collat_value} 
        </div>
        <div style={indented}>
            <strong>What Type of Real Estate Collateral Is Being Used For This Loan?</strong> {formData.collat_use} 
        </div>
        <div style={indented}>
            <strong>Add Any Additional Information You Think Necessary Regarding The Collateral:</strong> {formData.collat_desc} 
        </div>

    <h2>Construction/Rehab Details</h2>
        <div style={indented}>
            <strong>Estimated Dollar Amount of Construction Required:</strong> {formData.construct_price} 
        </div>
        <div style={indented}>
            <strong>What Is The Estimated "as-is" Value of the Property Before Construction?</strong> {formData.construct_as_value} 
        </div>
        <div style={indented}>
            <strong>What Is The Estimated "after-repair" Value of the Property After Construction?</strong> {formData.construct_after_value} 
        </div>

    <h2>Exit Strategy</h2>
        <div style={indented}>
            <strong>How Do You Intend To "Exit" The Loan?</strong> {formData.exit_strat} 
        </div>

    <h2>Borrower Experience</h2>
        <div style={indented}>
            <strong>Do You Own Other Investment Properties?</strong> {formData.other_prop} 
        </div>
        <div style={indented}>
            <strong>If "Yes" Please Enter Address(es) of Other Properties:</strong> {formData.other_prop_desc} 
        </div>
        <div style={indented}>
            <strong>Have You Done Projects Similar To This Before?</strong> {formData.similar_proj} 
        </div>

    <h2>Additional Information</h2>
        <div style={indented}>
            <strong>Questions/Additional Info:</strong> {formData.additional_desc} 
        </div>
        <div style={indented}>
            <strong>Lender will require property valuation/appraisal prior to approving your loan:</strong> {formData.legal_dec_1} 
        </div>
        <div style={indented}>
            <strong>Borrower responsible for all lender, title, appraisal, due diligence or 3rd party costs:</strong> {formData.legal_dec_2} 
        </div>
        <div style={indented}>
            <strong>All due diligence fees paid are non-refundable:</strong> {formData.legal_dec_3} 
        </div>
        <div style={indented}>
            <strong>Subject Property may not be borrower's primary residence. All loan proceeds will be used for commercial, business & investment purposes:</strong> {formData.legal_dec_4} 
        </div>
        </Grid>
      </Grid>

      {/* More content here... */}

    </Container>
  );
}

export default LoanFormat;
