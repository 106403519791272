import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import image from '../images/podcastImages/realEstatePodcast.jpg';

function PhotoTextRight() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      bgcolor: 'rgba(23, 23, 23, 1)',
      py: 5,
      px: 5,
      display: 'flex',
      alignItems: 'center', // Vertically center content
    }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8}>
        <Box
            component="img"
            src={image}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              //position: 'relative', // Set position to relative
              //right: '10%', // Move the image to the right by 50%
             
            }}
          />
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
         
        <Box
            sx={{
              //display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              //padding: '2rem',
              //position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              //zIndex: 1,
              
            }}
          >
            <Typography variant="h2" align="center" gutterBottom sx={{ color: 'white', borderBottom: '4px solid white', fontSize: { xs:'2rem' , sm: '3.5rem', md: '2.5rem', lg: '3.5rem'} }} >
            Podcast Overview
            </Typography>
            <Typography variant="h5" align={gridSizeXS ? "center": "left"} sx={{ my: 2, color: 'white', lineHeight: '1.7'  }} >
            To inform the public of the inner workings of real estate investing. Through examples that have been experienced with participants of the show, information will be conveyed through verbal experiences, video explanations, and guess experiences.
            </Typography>
            <Typography variant="h5" align={gridSizeXS ? "center": "left"} sx={{ my: 2, color: 'white', lineHeight: '1.7'  }} >
            We will discuss and educate followers on the elements of real estate investing such as:
            </Typography>
            <Typography variant="h5" align="left" sx={{ my: 2, color: 'white', lineHeight: '1.7'  }} >
            <ul>
              <li>Wholesaling</li>
              <li>Renovation</li>
              <li>Income-producing</li>
              <li>Property evaluation</li>
              <li>Rehab budgeting</li>
              <li>Acquiring investor clients</li>
              <li>Disposition & Leasing of property</li>
              <li>All Profitable Intricacies </li>
            </ul>
            </Typography>
            
          </Box>
        </Grid>
      </Grid>

      </Box>
  );
}

export default PhotoTextRight;
