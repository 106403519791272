import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import conservatory from '../images/mentorImages/meeting.jpg';

function PhotoTextRight() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);
 
  return (
    <Box
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      bgcolor: 'rgba(23, 23, 23, 1)',
      py: gridSizeXS ? 2 : 5,
      px: 5,
      display: 'flex',
      alignItems: 'center', // Vertically center content
    }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8}>
        <Box
            component="img"
            src={conservatory}
            sx={{
              width: '100%',
              height: '75vh',
              objectFit: 'cover',
              //position: 'relative', // Set position to relative
              //right: '10%', // Move the image to the right by 50%
             
            }}
          />
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
         
        <Box
            sx={{
              //display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              //position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              //zIndex: 1,
              
            }}
          >
            <Typography variant="h2" align="center" gutterBottom sx={{ color: 'white', borderBottom: '2px solid white', fontSize: { xs:'1.5rem' , sm: '3rem', md: '2rem', lg: '3rem'} }} >
            We Are Here To Help
            </Typography>
            <Typography variant="h5" align="center" sx={{ my: 2, color: 'white', lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1rem', lg: '1.5rem'}  }} >
            With over twenty years of experience, we are heavily relied on by hundreds of investors as the real estate forerunners. We are sought out by many for real estate questions, investments, and training in Baltimore. We teach hundreds of people yearly on how to build wealth quickly and show them how to achieve their goals within five years.
            </Typography>
            <Typography variant="h5" align="center" sx={{ my: 2, color: 'white', lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1.25rem', md: '1rem', lg: '1.5rem'}  }} >
            We have been representing this easy to learn technique for years. Now that our technique is getting national attention, we want you to be apart of the movement. Get started today, and we will guide you in the method named the BRRRR, Buy Rehab Rent Refinance and Repeat. 
            </Typography>
            
            
          </Box>
        </Grid>
      </Grid>

      </Box>
  );
}

export default PhotoTextRight;
