import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { 
    Typography,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function PropertyCard({ property }) {
    const navigate = useNavigate();
    //const [saleMethod, SetSaleMethod] = useState(property.salemethod)

    const [photoUrl, setPhotoUrl] = useState([]);

    useEffect(() => {
        //console.log("Property:", property)
        
        // Configure AWS SDK
        AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
        });
        const s3 = new AWS.S3();

        // Fetch photo URLs from S3
        const params = {
            Bucket: 'skyymylesproperties',
            Prefix: `${property.address}`, // Specify the folder name here
          };

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.error(err);
                return;
            }
            //console.log("Data:", data.Contents);

            const folder = data.Contents.filter((obj) => obj.Key.includes(`${property.address}`));
            //console.log("Folder:", property.address.trim(), folder);
            const frontImg = folder.find((obj) => obj.Key.includes("front.")); // Use find to get a single object
            //console.log("Img?:", data.Contents);

            //Get Front Image URL
            if (frontImg) {
                //console.log("Front:", frontImg.Key, frontImg);
                const url = s3.getSignedUrl('getObject', { Bucket: 'skyymylesproperties', Key: frontImg.Key });
                setPhotoUrl(url);
            } else {
                //Get Front Image not found, use first image as defaulted image 
                if(folder.length > 0){
                    const defaultImageUrl = folder[1]
                    const defaulturl = s3.getSignedUrl('getObject', { Bucket: 'skyymylesproperties', Key: defaultImageUrl.Key });
                    setPhotoUrl(defaulturl);
                    //console.log("PhotoURL:", defaulturl);
                }
                
            }
    
        });
    }, [property.address]);

    /*if(property.salemethod === 'wholesale'){
        SetSaleMethod("Wholesale");
    } else if (property.salemethod === 'turnkey'){
        SetSaleMethod("Turnkey");
    } else {
        SetSaleMethod("For Rent");
    }*/

    const handleCardClick = () => {
        // Navigate to the PropertyDetail page and pass the property object as state
        navigate(`/properties/${property.address.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "").toLowerCase()}`  , { state: { property } });
    };

    //console.log("Property:", property);
    return (
        <Card sx={{ maxWidth: 600, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',}} >
        <CardActionArea onClick={handleCardClick}>
            <CardMedia
            component="img"
            height="300"
            image={photoUrl}
            />
            <Box //Black bar
            sx={{
                background: 'black',
                color: 'white',
                textAlign: 'center',
                padding: '15px 0', 
            }}
            >
                {property.salemethod && (
                <Typography variant="h5" component="div">
                    {property.salemethod.charAt(0).toUpperCase() + property.salemethod.slice(1)}
                </Typography>
                )}
            </Box>
            <CardContent>

            {property.address && (
            <Typography align="center" sx={{fontSize: '2rem'}}>
                {property.address}
            </Typography>
            )} 

            {property.city && property.state && property.zip && (
            <Typography gutterBottom variant="body1" color="text.secondary" align="center" sx={{mb: 1}}>
                {property.city}, {property.state} {property.zip}
            </Typography>
            )}

            {property.salemethod && property.salemethod.toLowerCase() === "rental" &&
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                    ${property.rentprice.toLocaleString()}.00/month
                </Typography>
            }

            {property.salemethod && property.salemethod.toLowerCase() === "wholesale" &&
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                ${property.price.toLocaleString()}.00
                </Typography>
            }

            {property.salemethod && property.salemethod.toLowerCase() === "turnkey" && 
                <Typography align="center" sx={{fontSize: '1.75rem'}}>
                    ${property.price.toLocaleString()}.00
                </Typography>
            }         


            <Typography variant="body1" color="text.secondary" align="center">
                Details: {property.bedrooms} Bed | {property.bathrooms} Bath
            </Typography>
            </CardContent>
        </CardActionArea>
        </Card>
    );
}

export default PropertyCard;
