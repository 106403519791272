import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import houses from '../images/background/services_houses.jpg';

function Services() {
  return (
    <Container component="section" sx={{ mt: 20, mb: 20 }}>
      <Grid container>
        {/* Larger Picture on the Left Side */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={houses}
            sx={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
              position: 'relative', // Set position to relative
              right: '10%', // Move the image to the right by 50%
              //transform: 'translateX(-50%)', // Center the image horizontally
            }}
          />
        </Grid>

        {/* Right-side Container */}
        <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              position: 'absolute',
              alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
            }}
          >
            <Typography variant="h2" sx={{ color: 'black' }} >
              Services Offered
              <span
                style={{
                  position: 'absolute',
                  top: '15%', // Position the underline directly under the typography
                  left: 0,
                  right: 0,
                  height: '3px',
                  backgroundColor: 'black',
                  transform: 'translateY(5px)', // Move the underline up by its height
    
                }}
              />
            </Typography>
            
            <Typography variant="h5" component="div" sx={{ my: 1 }}>
              <ul>
                <li>Re-key and Secure Properties</li>
                <li>Inform Lender of Occupancy or Status</li>
                <li>Complete Broker’s price opinion (fully licensed)</li>
                <li>Arrange for trash removal</li>
                <li>Initiate utilities</li>
                <li>Winterize property (when needed)</li>
                <li>Arrange for lawn care and cleaning</li>
                <li>Suggest appropriate repair or renovation</li>
                <li>Open bids for repair or renovation</li>
                <li>Discuss potential marketing hazards with lender</li>
                <li>Arrange for inspection of property by Appraiser</li>
                <li>Maintain contact with lender until property listed</li>
                <li>Before and After digital photos of property and work</li>
                <li>Eviction and Tenant Placement Services</li>
              </ul>
            </Typography>
            
            
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Services;
