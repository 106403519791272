import * as React from 'react';
import withRoot from './withRoot.js';

import AboutHero from './TCHero.js';
import TermsConditionsDocument from './TermsConditionsDocument.js';

function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <TermsConditionsDocument/>
    </React.Fragment>
  );
}

export default withRoot(Index);
