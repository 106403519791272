import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
  MenuItem,
  Popover,
  Box,
  Alert,
  Snackbar,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel, 
  FormLabel,
  InputAdornment,
} from '@mui/material';

import axios from 'axios';
import config from '../config.js';
const environment = process.env.NODE_ENV || 'development';  // Determine the environment (e.g., development or production)
// Set the base URL for Axios requests
const api = axios.create({
  baseURL:  config[environment].apiUrl.replace('/api', ''),
});

function FormPage() {
  const [formData, setFormData] = useState({
    property: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    dob: '',
    ssn: '',

    //Voucher Info
    voucher: '',
    vouch_amount: '',
    vouch_program: '',

    //Curr Resident
    country: '',
    addressL1: '',
    addressL2: '',
    city: '',
    state: '',
    zip: '',
    inspect: '',
    occupants: '',
    length: '',
    resident_history: '',

    //Employment
    employment: '',
    income: '',
    income2: '',

    //Other
    court: '',
    bge: '',
    military: '',
    landlord: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [anchorSubmit, setAnchorSubmit] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alertOnError, setAlertOnError] = useState(false);
  const [alertOnCodeError, setAlertOnCodeError] = useState(false);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    //console.log("Change:", name, value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const confirmSubmit = (e) => {
    e.preventDefault();
    setAnchorSubmit(e.currentTarget);
    setOpenPopover(true);
  }

  const handleClose = () => {
    setAnchorSubmit(null);
    setOpenPopover(false);
  }

  const alertClose = () => {
    setAlertOn(false);
    setAlertOnError(false);
    setAlertOnCodeError(false);
  }

  const handleSubmit = async (e) => {
    //console.log("Form Submitting");
    e.preventDefault();
    setOpenPopover(false);
    setAnchorSubmit(null);

    // Validate the form fields
    const errors = {};
    for (const key in formData) {
      if (formData[key] === '' && key !== 'message' 
          && key !== 'addressL2' && key !== 'income2' 
          && key !== 'ssn' && key !== 'vouch_program'  && key !== 'vouch_amount'
          && key !== 'resident_history'
        ) {
        errors[key] = 'This field is required';
      }
    }
    
    // If there are validation errors, update the state and prevent submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setAlertOnError(true);
      //console.log("Errors:", errors);
      return;
    }

    console.log("Final Form Data:", formData);
    //Send Rental Form Data
    await api.post('/api/sendRentalFrom', formData)
      .then((response) => {
        //console.log("Sent Form: ", response.data);
        setAlertOn(true);
      })
      .catch((error) => {
        console.log("Send Rental Error:", error);
        setAlertOnCodeError(true);
      });

    //Send Rental Form to Al
    await api.post('/api/sendAlEmail', formData)
      .then((response) => {
        //console.log("Sent Form Email: ", response.data);
      })
      .catch((error) => {
        console.log("Email Error:", error);
      });
     
  };

  return (
    <Container maxWidth="md" backgroundColor="#f9f9f9" sx={{mb: 5}}>
      <form >
        <Typography variant="h2" align="center" sx={{mt: 2}}>
          Rental Application
        </Typography>
        <Typography  fullWidth variant="h6" align="center" sx={{ fontFamily: "Montserrat"}} >
            Please fill out the following information to apply for a rental property. 
        </Typography>
        <Typography  fullWidth variant="h5" align="center" sx={{ fontFamily: "Montserrat"}} >
            There will be an additional $35 per adult fee added to applications for background checks. 
        </Typography>
        <Typography  fullWidth variant="body1" align="center" gutterBottom sx={{mt: 1, mb: 2}}>
            Note: Fields marked with '*' are required
        </Typography>

        <Grid container spacing={2}>
   
            <Grid item xs={12}>
            <TextField
                required
                label="Current Listings/Property Applying For"
                name="property"
                fullWidth
                variant="outlined"
                value={formData.property}
                onChange={handleInputChange}
                error={!!formErrors.property}
                helperText={formErrors.property || "Address of the Listing/Property you are applying for"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                required
                label="First Name"
                name="firstname"
                fullWidth
                variant="outlined"
                value={formData.firstname}
                onChange={handleInputChange}
                error={!!formErrors.firstname}
                helperText={formErrors.firstname || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
                required
                label="Last Name"
                name="lastname"
                fullWidth
                variant="outlined"
                value={formData.lastname}
                onChange={handleInputChange}
                error={!!formErrors.lastname}
                helperText={formErrors.lastname || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Email"
                name="email"
                fullWidth
                variant="outlined"
                value={formData.email}
                onChange={handleInputChange}
                error={!!formErrors.email}
                helperText={formErrors.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Phone Number"
                name="phone"
                fullWidth
                variant="outlined"
                value={formData.phone}
                onChange={handleInputChange}
                error={!!formErrors.phone}
                helperText={formErrors.phone || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Date of Birth"
                name="dob"
                fullWidth
                variant="outlined"
                value={formData.dob}
                onChange={handleInputChange}
                error={!!formErrors.dob}
                helperText={formErrors.dob || "MM/DD/YYYY"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Last Four Digits of SSN"
                name="ssn"
                fullWidth
                variant="outlined"
                value={formData.ssn}
                onChange={handleInputChange}
                error={!!formErrors.ssn}
                helperText={formErrors.ssn || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Are you are program voucher holder?</FormLabel>
                <RadioGroup
                  row
                  name="voucher"
                  onChange={handleInputChange}
                  defaultValue="no"
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
          </Grid>

          {formData.voucher === 'yes' && (
           <Grid container spacing={2} >
              <Grid item xs={12}>
                <TextField
                  label="Voucher Program Name"
                  name="vouch_program"
                  fullWidth
                  variant="outlined"
                  value={formData.vouch_program}
                  onChange={handleInputChange}
                  error={!!formErrors.vouch_program}
                  helperText={formErrors.vouch_program || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Voucher Amount"
                  name="vouch_amount"
                  fullWidth
                  variant="outlined"
                  //startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  value={formData.vouch_amount}
                  onChange={handleInputChange}
                  error={!!formErrors.vouch_amount}
                  helperText={formErrors.vouch_amount || ""}
                />
              </Grid>
              </Grid>
          )}



            <Divider
                role="presentation"
                sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
            >
                Current Residence Information
            </Divider>

          <Grid item xs={12}>
            <TextField
                required
                label="Country"
                name="country"
                fullWidth
                variant="outlined"
                value={formData.country}
                onChange={handleInputChange}
                error={!!formErrors.country}
                helperText={formErrors.country || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Address Line 1"
                name="addressL1"
                fullWidth
                variant="outlined"
                value={formData.addressL1}
                onChange={handleInputChange}
                error={!!formErrors.addressL1}
                helperText={formErrors.addressL1 || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Address Line 2"
                name="addressL2"
                fullWidth
                variant="outlined"
                value={formData.addressL2}
                onChange={handleInputChange}
                error={!!formErrors.addressL2}
                helperText={formErrors.addressL2 || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
                required
                label="City"
                name="city"
                fullWidth
                variant="outlined"
                value={formData.city}
                onChange={handleInputChange}
                error={!!formErrors.city}
                helperText={formErrors.city || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
                required
                label="State"
                name="state"
                fullWidth
                variant="outlined"
                value={formData.state}
                onChange={handleInputChange}
                error={!!formErrors.state}
                helperText={formErrors.state || ""}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
                required
                label="ZIP Code"
                name="zip"
                fullWidth
                variant="outlined"
                value={formData.zip}
                onChange={handleInputChange}
                error={!!formErrors.zip}
                helperText={formErrors.zip || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            required
            select
            label="May We Inspect Your Current Residence?"
            name="inspect"
            fullWidth
            defaultValue="Yes"
            value={formData.inspect}
            onChange={handleInputChange}
            error={!!formErrors.inspect}
            helperText={formErrors.inspect || ""}
            >
                <MenuItem key="No" value="No">No</MenuItem>
                <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            </TextField>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
                required
                label="Full Name(s), Age(s), Date of Birth(s), and Last four SSN digits of All Person(s) Occupying the Premises"
                name="occupants"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Ex) John Doe (47), Donny Doe (16), Jain Doe (45)"
                value={formData.occupants}
                onChange={handleInputChange}
                error={!!formErrors.occupants}
                helperText={formErrors.occupants || "Who will be living/occupants at the applied property? Ex) John Doe 34, 01/01/2000, 1234."}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Length at Current Residence?"
                name="length"
                fullWidth
                variant="outlined"
                value={formData.length}
                onChange={handleInputChange}
                error={!!formErrors.length}
                helperText={formErrors.length || "How long have you been at your current residence?"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
            InputLabelProps={{ shrink: true, }}
                label="Resident History"
                name="resident_history"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Ex) 01/01/2017-12/30/2024: John Doe 443-123-1234, 123 Street, Baltimore, MD 12345"
                value={formData.resident_history}
                onChange={handleInputChange}
                error={!!formErrors.resident_history}
                helperText={formErrors.resident_history || "List the last 7 years or 2 previous residence info. Include lease date(s), address, and landlord info. If none, put 'None'"}
            />
          </Grid>
      



          <Divider
              role="presentation"
              sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
          >
              Employment Information
          </Divider>

          <Grid item xs={12}>
            <TextField
                InputLabelProps={{ shrink: true, }}
                required
                label="Applicant's Current Employment"
                name="employment"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.employment}
                onChange={handleInputChange}
                error={!!formErrors.employment}
                placeholder='Ex) SkyyMyles LLC, 1234 Road Baltimore MD, 443-123-1234, 01/01/2000-01/01/2024, My Title, $67,000/year'
                helperText={formErrors.employment || "Please include name of company, address, contact number, dates of employment, position title, and pay rate"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Estimated Primary Income"
                name="income"
                fullWidth
                variant="outlined"
                value={formData.income}
                onChange={handleInputChange}
                error={!!formErrors.income}
                helperText={formErrors.income ||"What is your estimated yearly income from your primary job?"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Estimated Income From Secondary Source"
                name="income2"
                fullWidth
                variant="outlined"
                value={formData.income2}
                onChange={handleInputChange}
                error={!!formErrors.income2}
                helperText={formErrors.income2 ||"If you have a secondary income (Ex. Side/Part Time Jobs, Child Support, etc), please provide the information."}
            />
          </Grid>


          <Divider
                role="presentation"
                sx={{ width: '100%', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', mt: 2, fontSize: {xs: "1.25rem", sm: "2rem", md: "2.5rem"}}}
            >
                Other Questions
            </Divider>

          <Grid item xs={12}>
            <TextField
            required
            select
            label="Have you ever received a Rent Court Notice?"
            fullWidth
            name="court"
            value={formData.court}
            onChange={handleInputChange}
            error={!!formErrors.court}
            helperText={formErrors.court || ""}
            >
                <MenuItem key="No" value="No">No</MenuItem>
                <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
            required
            select
            label="Do you have an account with Baltimore Gas & Electric?"
            fullWidth
            name="bge"
            value={formData.bge}
            onChange={handleInputChange}
            error={!!formErrors.bge}
            helperText={formErrors.bge || ""}
            >
                <MenuItem key="No" value="No">No</MenuItem>
                <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
            required
            select
            label="Anyone past or present military on lease?"
            fullWidth
            name="military"
            value={formData.military}
            onChange={handleInputChange}
            error={!!formErrors.military}
            helperText={formErrors.military || ""}
            >
                <MenuItem key="No" value="No">No</MenuItem>
                <MenuItem key="Yes" value="Yes">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
                required
                label="Current Landlord"
                name="landlord"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={formData.landlord}
                onChange={handleInputChange}
                error={!!formErrors.landlord}
                helperText={formErrors.landlord || "Who is your current landlord? Please included company/organization's name and contact number"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
                label="Other Descriptions"
                name="message"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={formData.message}
                onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        
        <Button
          type="submit"
          variant="contained"
          color="info"
          fullWidth
          size="large"
          sx={{mt: 3, color: 'white'}}
          onClick={confirmSubmit}
        >
          Submit
        </Button>
        <Popover
          open={openPopover}
          anchorEl={anchorSubmit}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{p:2}}> Are you sure you want to submit?</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1}}>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="success">
                Sumbit
              </Button>
          </Box>
        </Popover>

        <Snackbar
          open={alertOn}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='success'
            variant='filled'
          >
            Application Submitted
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertOnError}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='warning'
            variant='filled'
          >
            Error - Missing Fields
          </Alert>
        </Snackbar>

        <Snackbar
          open={alertOnCodeError}
          autoHideDuration={4000}
          onClose={alertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={alertClose}
            sx={{width: '100%'}}
            severity='error'
            variant='filled'
          >
            Form Error Unknown
          </Alert>
        </Snackbar>
      </form>
    </Container>
  );
}

export default FormPage;
