import React, { useEffect } from 'react';
import withRoot from './withRoot';

import ProductHero from './LoanHero';
import FormPage from './Form';
import PhotoTextLeft from './PhotoTextLeft';
import CenterText from './CenterText';
import CenterTextDetails from './CenterTextDetails'

import { Form } from 'react-router-dom';

function Index() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <ProductHero/>
      <CenterTextDetails/>
      <PhotoTextLeft/>
      <CenterText/>
      
    </React.Fragment>
  );
}

export default withRoot(Index);
