import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography.js';
import AboutHeroLayout from './PropertyHeroLayout';
//import backgroundImage from '../images/propertiesImages/buildings.jpg';

export default function ProductHero(imports) {
  //console.log("Hero Img Url:", imports);
  return (
    <AboutHeroLayout
      property={imports.property}
      sxBackground={{
        backgroundImage: `url(${imports.imgUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    />

  );
}
