import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ images }) => {
  /*const settings = {
    dots: false,
    infinite: true,
    speed: 10000, // Adjust the speed (higher value = slower scroll)
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 to enable smooth scrolling
    cssEase: 'linear', // Use linear easing for smoother scroll
    centerMode: true,
    centerPadding: '0',
  };*/

  const settings = {
    dots: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear', // Use linear easing for smoother scroll
    centerMode: true, // Enable center mode
    centerPadding: '0', // Set center padding to 0
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };

  const slideStyle = {
    width: '150px',
    height: '150px',
  };

  const imageStyle = {
    width: '100%',
    //height: '10%', // Set a fixed height to make images the same size
    borderRight: '1px solid black',
  };


  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index} style={slideStyle}>
          <img
            src={image}
            alt={`Slide ${index}`}
            style={imageStyle}
          />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
