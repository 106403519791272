import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import { Link } from 'react-router-dom';

function CenterText() {


  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    
    <Container component="section" sx={{ mt: 1, mb: 5, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h3" gutter align="center" sx={{ color: 'white', mt: 2, mb: 2, textDecoration: "underline" }} >
          Reasons to Apply
          </Typography>
          <Typography variant="h5" align="center" sx={{ mb: 4, color: 'white', lineHeight: '1.7', fontSize: '1.25rem'  }} >
          Our versatile loans serve a variety of purposes in the real estate landscape. Whether you're looking to acquire bank-owned REO properties, participate in short sales, or invest in distressed properties, our financing solutions have you covered. Additionally, we provide the financial support needed for rehabilitating, repairing, and restoring real estate projects. Our property bridge financing options ensure swift transactions, making your real estate endeavors seamless. For non-owner-occupied properties facing foreclosure or bankruptcy, our loans offer a lifeline. Furthermore, our flexible financing extends to both commercial and residential investment properties, giving you the freedom to explore various real estate opportunities
          </Typography>
          <Typography variant="h4" gutter align="center" sx={{ color: 'white',  lineHeight: '1.7'}} >
          Contact 443-871-8980 for Questions
          </Typography>

        </Grid>
        
               
      </Grid>
    </Container>
    </Box>
  );
}

export default CenterText;
