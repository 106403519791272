import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import { Link } from 'react-router-dom';

import background from '../images/background/loan2.jpg'

function PhotoTextLeft() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Container component="section" sx={{ mt: gridSizeXS ? 5 : 15, mb: gridSizeXS ? 5 : 15 }}>
      <Grid container align="center" >
        
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'inline-block',
              justifyContent: 'center',
              padding: gridSizeXS ? '.25rem' : '2rem',
              backgroundColor: 'rgba(255, 255, 255, 0.6)', // Slightly translucent background
              borderRadius: '10px', // Rounded corners
            }}
          >
    
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography variant="h5" gutter align="center" sx={{ color: 'black', }} >
          For a fast lending decision
          </Typography>
          <Typography variant="h5" gutter align="center" sx={{ color: 'black', mb: 2 }} >
          complete our application:
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} align="center" sx={{ position: 'relative'}}>
          <Link to="/loan-application">
            <Button 
              variant="contained" 
              color="success" 
              sx={{ borderRadius: '10px' }}
            >
              Loan Application
            </Button>
          </Link>
        </Grid>    
            
          </Box>
        </Grid>       
      </Grid>
    </Container>
    </Box>
  );
}

export default PhotoTextLeft;
