import React from 'react';

const TermsConditionsDocument = () => {
  const pdfUrl = '/terms and conditions_v3.pdf'; // Provide the path to your PDF document here

  return (
    <div>
        <iframe src={pdfUrl} width='100%' height='1000px' />
    </div>
  );
};

export default TermsConditionsDocument;
