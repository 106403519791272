import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import Button from '../components/Button.js';

import ImageLeft from '../images/promotionsImages/GTDLogo_small.png';

function PhotoTextRight() {

  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);

  return (
    <Box
    sx={{
      flexGrow: 1,
      overflow: 'hidden',
      bgcolor: 'rgba(23, 23, 23, 1)',
      py: 0,
      px: 5,
      display: 'flex',
      alignItems: 'center', // Vertically center content
    }}
    >
      <Grid container spacing={2} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8}>
        <Box
            component="img"
            src={ImageLeft}
            sx={{
              width: '100%',
              height: '90%',
              objectFit: 'cover',
              mt: 5.
            }}
          />
        </Grid>

        {/* Right Side */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '1rem',
                alignItems: 'center', // Center text horizontally
              }}
            >
              <Typography variant="h4"  gutterBottom 
                sx={{ 
                  borderBottom: '2px solid white', 
                  color: 'white', 
                  fontSize: { xs:'2.5rem' , sm: '3rem', md: '2rem', lg: '3rem'},
                  textAlign: { xs: "center", md: "center" },
                  mt: 3,
                }} >
                <span style={{ fontWeight: 'bold' }}>Partnerships</span>
              </Typography>
              <Typography variant="h5" align="center" sx={{ my: 2, color: 'white', lineHeight: '1.7', fontSize: { xs:'1rem' , sm: '1rem', md: '1.25rem', lg: '1.55rem'}  }} >
                With partners like GTD, we are able to forge strategic partnerships with other top-tier promoters to deliver larger, more impactful events. 
                We collaborate with leading brands for sponsorship opportunities, ensuring our clients receive maximum exposure and support. 
                Through these partnerships, we are able to offer unmatched networking opportunities, connecting our clients with key industry professionals 
                and potential business allies.
              </Typography>
              
              
            </Box>
        </Grid>
      </Grid>

      </Box>
  );
}

export default PhotoTextRight;
