import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography.js';
import TextField from '../components/TextField.js';
import Snackbar from '../components/Snackbar.js';
import Button from '../components/Button.js';

import { Link } from 'react-router-dom';

function CenterTextDetails() {


  return (
    <Box
    component="section"
    sx={{
      display: 'flex',
      overflow: 'hidden',
      position: 'relative',
      bgcolor: '#171717',
    }}
  >
    
    <Container component="section" sx={{ mt: 5, mb: 1, bgcolor: '#171717' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ position: 'relative' }}>
          <Typography gutter align="center" sx={{ color: 'white', mb: 2, textDecoration: "underline", fontSize: '4.5rem' }} >
          <span style={{ fontWeight: 'bold' }}>Private/Hard Money Loans</span>
          </Typography>
          <Typography gutter align="center" sx={{ color: 'white', mb: 3, lineHeight: '1.7', fontSize: '1.75rem'}} >
          Private Financing For Business and Real Estate Investments 
          </Typography>

          <Typography variant="h5" align="center" sx={{ mb: 4, color: 'white', lineHeight: '1.7', fontSize: '1.25rem'  }} >
            Unlike conventional loans, private loans are underwritten by individuals lending their own money and with common sense underwriting standards, so the <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}> loans can be approved very quickly, and closings can occur in days or weeks </span> , rather than weeks or months as often the case with a conventional loan.
            If you need quick financing on a hot real estate deal, or urgently need capital for any reason, a hard money loan may be right for you.
          </Typography>

          
        </Grid>
        
        

       
      </Grid>
    </Container>
    </Box>
  );
}

export default CenterTextDetails;
