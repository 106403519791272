import * as React from 'react';
import withRoot from './withRoot';

import ProductHero from './ConstructHero';
import PhotoTextLeft from './PhotoTextLeft';

function Index() {
  return (
    <React.Fragment>
      <ProductHero/>
      <PhotoTextLeft/>
    </React.Fragment>
  );
}

export default withRoot(Index);
