import React, { useEffect, useState } from 'react';
import { 
  Grid, 
  Container, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  InputAdornment,
  IconButton,
  Divider,
  FormControlLabel,
  ListItemText,
  Checkbox,
  Box,
  Typography,
} from '@mui/material';

import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';

import PropertyMap from './Map';

function MapTextLeft() {
  const [gridSizeXS, setGridSizeXS] = useState(false);

  useEffect(() => {
    
    // Define your custom breakpoints here
    if (window.innerWidth < 769) {
      setGridSizeXS(true)
    } 

  }, []);


  return (
    <Box
      sx={{ flexGrow: 1, overflow: 'hidden', bgcolor: '#f9f9f9', px: 5, pt: 2 }}
    >
      <Grid container spacing={4} columns={16}>

        {/* Left-side Container */}
        <Grid item xs={16} md={8} sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //justifyContent: 'center',
              //padding: '2rem',
              //position: 'relative',
              //alignItems: 'center', // Center text horizontally
              top: '50%', // Adjust vertical alignment
              //transform: 'translateY(-50%)', // Adjust vertical alignment
              zIndex: 1,
              
            }}
          >
            <Typography variant="h2" align='center' sx={{ color: 'black', mb: 1, fontSize: { xs:'3rem' , sm: '4rem', md: '3.5rem', lg:"4rem"} }} >
            Property Details
            </Typography>
            <Divider role="presentation" style={{width:'100%', border: '2px solid black',}}/>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1, mb: 1 }}>
              <BedIcon sx={{ fontSize: 45, mr: 1 }} /> {/* You need to replace BedIcon with your actual bed icon */}
              <Typography variant="h6" align="center" sx={{ mr: 5 }}>
                3 Beds
              </Typography>
              <BathtubIcon sx={{ fontSize: 40, mr: 1 }} /> {/* You need to replace BathIcon with your actual bath icon */}
              <Typography variant="h6">
                2.5 Baths
              </Typography>
            </Box>


            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Type:</span> Townhouse
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Price:</span> TBD
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Negotiable?:</span> TBD
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Status:</span> In Progress - Development Stages
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Date Listed:</span> TBD
            </Typography>

            <Typography variant="h5" align="left" sx={{ alignItems: 'left', color: 'black', lineHeight: '1.7'  }} >
            <span style={{ fontWeight: 'bold' }}>Details:</span> Developing - Subject to Change
            </Typography>

          </Box>
        </Grid>

        {/* Right Side Map*/}
        <Grid item xs={16} md={8}>
          <PropertyMap />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MapTextLeft;
