import * as React from 'react';
import withRoot from './withRoot';

import AboutHero from './AboutHero.js';
import MeetAl from './MeetAl.js';
import MeetSkyyMyles from './MeetSkyyMyles.js';
import Services from './Services.js';
import More from './More.js';
import ProductCategories from './ProductCategories';
import Carousel from '../homePageElements/Carousel';

function Index() {
  return (
    <React.Fragment>
      <AboutHero/> 
      <MeetSkyyMyles/>
      <MeetAl/>    
      <ProductCategories/>
      <More/> 
    </React.Fragment>
  );
}

export default withRoot(Index);
